export default class Combo {
  constructor(scene) {
    this.scene = scene;
    this.comboOfFever = 6;
  }

  updateCombo() {
    if (this.scene.combo > this.comboOfFever) {
      this.updateIsFeverTrue();
    } else {
      this.updateIsFeverFalse();
    }

    this.scene.comboPad = this.scene.combo.toString().padStart(7, '0');

    if (this.scene.top_bar_combo) {
      this.scene.top_bar_combo.setText(this.scene.comboPad);

      if (this.scene.isFirstComboUpdate) {
        this.scene.isFirstComboUpdate = false;

        this.scene.comboImage.setVisible(false);
        this.scene.top_bar_combo.setVisible(true);
      }
    }
  }

  setComboVisible() {
    if (this.scene.top_bar_combo) {
      this.scene.top_bar_combo.setVisible(true).setDepth(100);
    }

    if (this.scene.combo > 0) {
      for (let i = 1; i <= this.scene.combo; i++) {
        if (i <= 7) {
          this.scene[`combo_gauge_${i}`].setVisible(true);
        }
      }
    } else {
      for (let i = 1; i <= 7; i++) {
        this.scene[`combo_gauge_${i}`].setVisible(false);
      }
    }

    return true;
  }

  updateIsFeverTrue() {
    this.scene.isFever = true;
  }

  updateIsFeverFalse() {
    this.scene.isFever = false;
  }

  addCombo() {
    this.scene.combo++;
    this.updateCombo();
  }

  resetCombo() {
    this.scene.combo = 0;
    this.updateCombo();
  }
}