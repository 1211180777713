export default class ComboToImage{
    constructor(scene){
        this.scene = scene;
    }

    convertImage(combo){
        const comboStr = combo.toString();
        const digits = comboStr.split('');
        const images = [];
        const imageWidth = 80;
        const startX = this.scene.coordinate.xPosit.center - (digits.length * imageWidth - 40) / 2;
        const y = 1350;

        digits.forEach((digit, index) => {
            const image = this.scene.add.image(startX + index * imageWidth, y, `combo_${digit}`).setOrigin(0.5).setVisible(false);
            images.push(image);
        });

        return images;
    }
}