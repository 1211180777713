export default class PositionCalc {
    yGameCenterCalc(game, asset) {
        const game_height = game.config.height;
        const asset_height = asset.height * asset.scaleY;

        return (game_height-asset_height) / 2;
    }

    xGameCenterCalc(game, asset){
        const game_width = game.config.width;
        const asset_width = asset.width * asset.scaleX;
        
        return (game_width-asset_width) / 2;
    }

    xFractionalPosition(game, width, total){
        return (game.config.width / total) * width;
    }

    yFractionalPosition(game, height, total){
        return (game.config.height / total) * height;
    }
}