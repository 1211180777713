export default class Music {
    /**
     * 
     * @param {*} index 
     * @param {*} title 
     * @param {*} artist 
     * @param {*} musicPath 
     * @param {*} nodeFilePath 
     */
    constructor(index, title, artist, musicPath, nodeFilePath){
        this.id = index;
        this.title = title;
        this.artist = artist;
        this.musicPath = musicPath;
        this.nodeFilePath = nodeFilePath;
    }
}