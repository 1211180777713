import Phaser from 'phaser';

const gameKeys = ['D', 'F', 'K', 'J'];
const scores = {
  perfectScore: 200,
  greatScore: 150,
  goodScore: 100,
};

export default class KeyboardEvent {
  constructor (scene, device) {
    this.scene = scene;
    this.activeKeys = {};
    this.perfectDistance = device === 'mob' ? 100 : 200;
    this.greatDistance = device === 'mob' ? 200 : 400;
    this.goodDistance = device === 'mob' ? 150 : 300;
    this.earlyDistance = device === 'mob' ? 300 : 600;
    this.maxJudgementDistance = device === 'mob' ? 300 : 600;
  }

  loadGameKey (device) {
    gameKeys.forEach (gameKey => {
      this['key' + gameKey] = this.scene.input.keyboard.addKey (
        Phaser.Input.Keyboard.KeyCodes[gameKey]
      );
      this['key' + gameKey].on ('down', () =>
        this.handleKeyDown (gameKey, device)
      );
      this['key' + gameKey].on ('up', () => this.handleKeyUp (gameKey, device));
    });
  }

  toggleGameKey (device) {
    if (this.scene.on_popup) {
      gameKeys.forEach (gameKey => {
        this['key' + gameKey].off ('down');
        this['key' + gameKey].off ('up');
      });
    } else {
      gameKeys.forEach (gameKey => {
        this['key' + gameKey].on ('down', () =>
          this.handleKeyDown (gameKey, device)
        );
        this['key' + gameKey].on ('up', () =>
          this.handleKeyUp (gameKey, device)
        );
      });
    }
  }

  handleKeyUp (key, device) {
    const lowerKey = key.toLowerCase ();
    this.scene[`key_${lowerKey}_button`].setScale (1); // 원래 크기로 되돌리기
  }

  handleKeyDown (key, device) {
    const correction = device === 'mob' ? 1 : 2;
    const lowerKey = key.toLowerCase ();

    // 버튼이 눌렸을 때 크기 줄이기
    this.scene[`key_${lowerKey}_button`].setScale (0.9);

    this.show_route_effect (lowerKey, device);
    this.show_middle_star_effect (lowerKey, device);
    this.show_small_star_effect (lowerKey, device);

    /* 판정할 노드를 필터링, 판정선 기준 ABS가 maxJudgementDistance까지 판정 */
    var judgmentNodes = this.scene.nodes.filter (
      node =>
        Math.abs (node.y - this.scene.coordinate.yPosit.judgementLine) <=
          this.maxJudgementDistance &&
        node.getData ('key').toUpperCase () === key
    );

    /* 판정할 노드를 가까운 순서대로 정렬 */
    judgmentNodes.sort (
      (a, b) =>
        Math.abs (a.y - this.scene.coordinate.yPosit.judgementLine) -
        Math.abs (b.y - this.scene.coordinate.yPosit.judgementLine)
    );

    /* 가장 가까운 노드만 판정 후 제거 */
    if (judgmentNodes.length > 0) {
      const closestNode = judgmentNodes[0];
      this.judgeNode (closestNode, key);
      this.scene.nodeManager.removeNode (closestNode);
    }
  }

  judgeNode (node) {
    const distance = Math.abs (
      this.scene.coordinate.yPosit.judgementLine - node.y
    );
    const judgementType = this.getJudementType (distance, node);
    this.processJudgement (judgementType, node);
  }

  getJudementType (distance, node) {
    if (distance <= this.perfectDistance) {
      return 'perfect';
    } else if (distance <= this.greatDistance) {
      return 'great';
    } else if (distance <= this.goodDistance) {
      return 'good';
    } else if (distance <= this.earlyDistance) {
      if (this.scene.coordinate.yPosit.judgementLine > node.y) {
        return 'early';
      } else {
        return 'miss';
      }
    } else {
      return 'miss';
    }
  }

  /* Process according to judgment type */
  processJudgement (type, node) {
    this.scene.judgementModule.changeJudgementText (type);

    if (type === 'early' || type === 'miss') {
      this.scene.comboModule.resetCombo ();
      // this.scene.scoreModule.setAddScoreNull();
    } else {
      this.scene.comboModule.addCombo ();
      let scoreValue = scores[type.toLowerCase () + 'Score'];
      this.scene.scoreModule.updateTotalScore (scoreValue);
      // this.scene.scoreModule.setAddScore(scoreValue);

      // 첫 판정 후 이미지 숨기기 및 텍스트 표시
      if (this.scene.isFirstJudgement) {
        this.scene.isFirstJudgement = false;

        this.scene.scoreImage.setVisible (false);
        this.scene.scoreObject.setVisible (true);
        console.log("REMOVE IMAGE")
      }
    }
    this.scene.nodeManager.removeNode (node);
  }

  missJudgement (nodeYPosit) {
    if (nodeYPosit > this.scene.coordinate.yPosit.judgementLine) {
      this.scene.judgementModule.changeJudgementText ('miss');
      this.scene.comboModule.resetCombo ();
      // this.scene.scoreModule.setAddScoreNull();
    }
  }

  show_route_effect (lowerKey, device) {
    const route_motion = this.scene.add
      .sprite (0, 0, `game_${device}_route_motion`)
      .play ('game_route_effect')
      .setDepth (1)
      .setScale (2);
    route_motion.x = this.scene[`key_${lowerKey}_button`].x;
    var gap = device === 'web' ? 0 : 50;
    route_motion.y =
      this.scene[`key_${lowerKey}_button`].y - route_motion.height + gap;

    route_motion.on ('animationcomplete', () => {
      route_motion.destroy ();
    });

    return route_motion;
  }

  show_middle_star_effect (lowerKey, device) {
    const middle_star_motion = this.scene.add
      .sprite (0, 0, `game_${device}_middle_star_motion`)
      .play ('game_middle_star_effect')
      .setDepth (3)
      .setScale (2);
    middle_star_motion.x = this.scene[`key_${lowerKey}_button`].x;
    var gap = device === 'web' ? 25 : 12.5;
    middle_star_motion.y += this.scene.game.config.height * 2 / 5 + gap;

    middle_star_motion.on ('animationcomplete', () => {
      middle_star_motion.destroy ();
    });
    return middle_star_motion;
  }

  show_small_star_effect (lowerKey, device) {
    const small_star_motion = this.scene.add
      .sprite (0, 0, `game_${device}_small_star_motion`)
      .play ('game_small_star_effect')
      .setDepth (5)
      .setScale (2);
    small_star_motion.x = this.scene[`key_${lowerKey}_button`].x;
    var gap = device === 'web' ? 25 : 12.5;
    small_star_motion.y += this.scene.game.config.height * 2 / 5 + gap;

    small_star_motion.on ('animationcomplete', () => {
      small_star_motion.destroy ();
    });

    return small_star_motion;
  }
}
