export default class JudgementToImage {
  constructor(scene) {
    this.scene = scene;
  }

  convertImage(judgementText) {
    const correction = this.scene.isMobile ? 1 : 2;
    const y_position = (judgementText === 'perfect' || judgementText === 'great' || judgementText === 'good') 
      ? this.scene.top_game_gauge.y + 420 * correction 
      : this.scene.top_game_gauge.y + 220 * correction;

    const animation = this.scene.add
      .sprite(
        this.scene.top_game_gauge.x + 220 * correction,
        y_position,
        judgementText
      )
      .setDepth(4)
      .setOrigin(0)
      .setVisible(true)
      .setScale(2);

    if (this.scene.anims.exists(judgementText)) {
      animation.play(judgementText);
      animation.on('animationcomplete', () => {
        animation.destroy();
      });
    } else {
      animation.destroy();
    }

    return animation;
  }
}