export default class ValidateDevice{
    constructor(scene){
        this.scene = scene;
    }

    isMobile(){
        let device = this.scene.sys.game.device;
        var isMobile = false;

        if(!device.os.desktop){
            isMobile = true;
        }

        return isMobile;
    }
}