import Phaser from 'phaser';

/* Temprory node text file */
import game1_node from '../assets/textNode/Finding_home.txt';
import game2_node from '../assets/textNode/NMMG.txt';
import game3_node from '../assets/textNode/Dome_before_30mins.txt';
import game4_node from '../assets/textNode/Look_at_that.txt';
import game5_node from '../assets/textNode/Oasis.txt';

/* CONNECT MODULE */
import Music from '../music_info/Music';
import ValidateDevice from '../theme/ValidateDevice';
import PositionCalc from '../theme/PositionCalc';
import FontFaceObserver from 'fontfaceobserver';

/* WEB VIEW ASSETS */
import main_web_bg from '../assets/img/main/web_view/background.png';
import main_web_top_bar from '../assets/img/main/web_view/top_bar.png';
import main_web_select_effect
  from '../assets/img/main/web_view/select_effect.png';
import main_web_music_object_1
  from '../assets/img/main/web_view/music_info/music_object_1.png';
import main_web_music_object_2
  from '../assets/img/main/web_view/music_info/music_object_2.png';
import main_web_music_object_3
  from '../assets/img/main/web_view/music_info/music_object_3.png';
import main_web_music_object_4
  from '../assets/img/main/web_view/music_info/music_object_4.png';
import main_web_music_object_5
  from '../assets/img/main/web_view/music_info/music_object_5.png';
import main_web_popup_bg
  from '../assets/img/main/web_view/pop_up/background.png';
import main_web_popup_content
  from '../assets/img/main/web_view/pop_up/content.png';
import main_web_popup_close_button
  from '../assets/img/main/web_view/pop_up/close_button.png';
import main_web_direction_moving_motion
  from '../assets/animation/main/main_web_direction_moving_motion.png';

/* MOBILE VIEW ASSETS */
import main_mob_bg from '../assets/img/main/mobile_view/background.png';
import main_mob_background_cover
  from '../assets/img/main/mobile_view/background_cover.png';
import main_mob_background_circuit
  from '../assets/img/main/mobile_view/background_circuit.png';
import main_mob_background_circuit_cover
  from '../assets/img/main/mobile_view/background_circuit_cover.png';
import main_mob_music_object_1
  from '../assets/img/main/mobile_view/music_info/music_object_1.png';
import main_mob_music_object_2
  from '../assets/img/main/mobile_view/music_info/music_object_2.png';
import main_mob_music_object_3
  from '../assets/img/main/mobile_view/music_info/music_object_3.png';
import main_mob_music_object_4
  from '../assets/img/main/mobile_view/music_info/music_object_4.png';
import main_mob_music_object_5
  from '../assets/img/main/mobile_view/music_info/music_object_5.png';
import main_mob_select_effect
  from '../assets/img/main/mobile_view/select_effect.png';
import main_mob_top_bar from '../assets/img/main/mobile_view/top_bar.png';
import main_mob_popup_bg
  from '../assets/img/main/mobile_view/popup/background.png';
import main_mob_popup_content
  from '../assets/img/main/mobile_view/popup/pop_up_content.png';
import main_mob_popup_close_button
  from '../assets/img/main/mobile_view/popup/pop_up_close_button.png';
import main_mob_direction_moving_motion
  from '../assets/animation/main/main_mob_direction_moving_motion.png';

import Coordinate from '../theme/Coordinate';

class Main extends Phaser.Scene {
  constructor () {
    super ({key: 'main'});

    this.musics = [
      new Music (
        1,
        'Finding Home',
        'cadejo|J,Clap|Ail Kim',
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Finding_home.mp3',
        game1_node
      ),
      new Music (
        2,
        '너도 먹고 물러 가라',
        '추다혜 차지스|윤석철',
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/NMMG.mp3',
        game2_node
      ),
      new Music (
        3,
        'Dome, before 30mins',
        'Do-eon Kim|Soo-young Jin',
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Dome_before_30mins.mp3',
        game3_node
      ),
      new Music (
        4,
        'Look At That',
        'Holland|Hypnosis Therapy',
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Look_at_that.mp3',
        game4_node
      ),
      new Music (
        5,
        'Oasis',
        'Cifika Wade',
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Oasis.mp3',
        game5_node
      ),
    ];

    /* CONNECT MODULE */
    this.positionCalc = new PositionCalc ();
    this.validateDevice = new ValidateDevice (this);
    this.coordinate = new Coordinate ();

    this.currentIndex = 1;
    this.images = [];
    this.startY = 0;
    this.endY = 0;
    this.on_popup = false;

    this.selectEffectTop = null; // selectEffect의 상단 부분
    this.selectEffectBottom = null; // selectEffect의 하단 부분

    // 색상 배열 정의
    this.colors = [
      Phaser.Display.Color.HexStringToColor ('#FC3D3D').color,
      Phaser.Display.Color.HexStringToColor ('#64FF70').color,
      Phaser.Display.Color.HexStringToColor ('#3292FF').color,
      Phaser.Display.Color.HexStringToColor ('#FDFF73').color,
      Phaser.Display.Color.HexStringToColor ('#F8CCFF').color,
      Phaser.Display.Color.HexStringToColor ('#64FFF8').color,
    ];
    this.colorIndex = 0; // 현재 색상 인덱스

    this.currentColor = Phaser.Display.Color.HexStringToColor ('#F8CCFF').color; // 초기 색상 설정
  }

  preload () {
    const isMobile = this.validateDevice.isMobile ();
    const device = isMobile ? 'mob' : 'web';

    if (isMobile) {
      /* LOAD MOBILE VIEW AESSETS */
      this.load.image ('main_mob_bg', main_mob_bg);
      this.load.image ('main_mob_background_cover', main_mob_background_cover);
      this.load.image (
        'main_mob_background_circuit',
        main_mob_background_circuit
      );
      this.load.image (
        'main_mob_background_circuit_cover',
        main_mob_background_circuit_cover
      );
      this.load.image ('main_mob_select_effect', main_mob_select_effect);
      this.load.image ('main_mob_top_bar', main_mob_top_bar);
      this.load.image ('main_mob_music_object_1', main_mob_music_object_1);
      this.load.image ('main_mob_music_object_2', main_mob_music_object_2);
      this.load.image ('main_mob_music_object_3', main_mob_music_object_3);
      this.load.image ('main_mob_music_object_4', main_mob_music_object_4);
      this.load.image ('main_mob_music_object_5', main_mob_music_object_5);
      this.load.image ('main_mob_popup_bg', main_mob_popup_bg);
      this.load.image (
        'main_mob_popup_close_button',
        main_mob_popup_close_button
      );
      this.load.image ('main_mob_popup_content', main_mob_popup_content);

      this.load.atlas (
        'main_mob_direction_moving_motion',
        main_mob_direction_moving_motion,
        '../assets/animation/main/main_mob_direction_moving_motion.json'
      );
    } else {
      /* LOAD WEB VIEW AESSETS */
      this.load.image ('main_web_bg', main_web_bg);
      this.load.image ('main_web_top_bar', main_web_top_bar);
      this.load.image ('main_web_select_effect', main_web_select_effect);
      this.load.image ('main_web_music_object_1', main_web_music_object_1);
      this.load.image ('main_web_music_object_2', main_web_music_object_2);
      this.load.image ('main_web_music_object_3', main_web_music_object_3);
      this.load.image ('main_web_music_object_4', main_web_music_object_4);
      this.load.image ('main_web_music_object_5', main_web_music_object_5);

      this.load.image ('main_web_popup_bg', main_web_popup_bg);
      this.load.image ('main_web_popup_content', main_web_popup_content);
      this.load.image (
        'main_web_popup_close_button',
        main_web_popup_close_button
      );
      this.load.atlas (
        'main_web_direction_moving_motion',
        main_web_direction_moving_motion,
        '../assets/animation/main/main_web_direction_moving_motion.json'
      );
    }
  }

  init () {
    this.resetGameState ();
    if (this.cache.audio.exists ('bgm')) {
      this.cache.audio.remove ('bgm');
    }
  }

  create () {
    const isMobile = this.validateDevice.isMobile ();
    const device = isMobile ? 'mob' : 'web';
    this.load_main_background_image (device);
    this.load_start_direction_motion (device);

    const top_bar = this.load_top_bar (device);

    this.selectEffectTop = this.load_select_effect_top_image (device);
    this.selectEffectBottom = this.load_select_effect_button_image (device);

    this.load_popup_button (top_bar, device);

    this.images = this.load_image_group (device);
    this.updateImageSizes (device);

    this.load_select_area ();

    // 터치 이벤트 추가
    this.input.on ('pointerdown', this.onPointerDown, this);
    this.input.on ('pointerup', this.onPointerUp, this);

    // 휠 이벤트
    this.input.on ('wheel', this.onWheel, this);

    /* 방향키로 게임 변경 */
    if (device === 'web') {
      this.load_direction_key_function ();
    }
  }

  onWheel (pointer, gameObjects, deltaX, deltaY, deltaZ) {
    if (deltaY > 0) {
      this.nextImage ();
    } else if (deltaY < 0) {
      this.prevImage ();
    }
  }

  onPointerDown (pointer) {
    this.startY = pointer.y;
  }

  onPointerUp (pointer) {
    this.endY = pointer.y;
    if (!this.on_popup) {
      this.handleSwipe ();
    }
  }

  handleSwipe () {
    const deltaY = this.endY - this.startY;
    if (deltaY > 10) {
      this.nextImage ();
    } else if (deltaY < -10) {
      this.prevImage ();
    }
  }

  nextImage () {
    if (this.isSliding) return; // 슬라이드 중이면 리턴
    this.isSliding = true; // 슬라이드 시작

    const offset = this.scale.height / 4.4; // 이미지 간의 간격 조정
    this.currentIndex = this.currentIndex - 1 < 1 ? 5 : this.currentIndex - 1;
    const targetColor = this.colors[this.colorIndex];
    this.colorIndex = (this.colorIndex + 1) % this.colors.length; // 다음 색상 인덱스로 이동

    this.tweens.add ({
      targets: this.images,
      y: `+=${offset}`,
      alpha: {
        getStart: (target, key, value) => target.alpha,
        getEnd: (target, key, value) => this.calculateAlpha (target.y + offset),
      },
      duration: 400,
      ease: 'Cubic.easeOut',
      onUpdate: (tween, target) => {
        const progress = tween.progress;
        const interpolatedColor = Phaser.Display.Color.Interpolate.ColorWithColor (
          Phaser.Display.Color.ValueToColor (this.currentColor),
          Phaser.Display.Color.ValueToColor (targetColor),
          100,
          progress * 100
        );
        const color = Phaser.Display.Color.GetColor (
          interpolatedColor.r,
          interpolatedColor.g,
          interpolatedColor.b
        );
        this.changeSelectEffectColor (color); // 색상 변경 함수 호출
        this.currentColor = targetColor;
      },
      onComplete: () => {
        this.images.forEach (image => {
          if (image.y > this.scale.height + image.displayHeight / 2) {
            image.y -= offset * this.images.length;
          }
        });
        this.updateImageSizes ();
        this.isSliding = false; // 슬라이드 종료
      },
    });
  }

  prevImage () {
    if (this.isSliding) return; // 슬라이드 중이면 리턴
    this.isSliding = true; // 슬라이드 시작

    const offset = this.scale.height / 4.4; // 이미지 간의 간격 조정
    this.currentIndex = this.currentIndex % 5 + 1;
    const targetColor = this.colors[this.colorIndex];
    this.colorIndex = (this.colorIndex + 1) % this.colors.length; // 다음 색상 인덱스로 이동

    this.tweens.add ({
      targets: this.images,
      y: `-=${offset}`,
      alpha: {
        getStart: (target, key, value) => target.alpha,
        getEnd: (target, key, value) => this.calculateAlpha (target.y - offset),
      },
      duration: 400,
      ease: 'Cubic.easeOut',
      onUpdate: (tween, target) => {
        const progress = tween.progress;
        const interpolatedColor = Phaser.Display.Color.Interpolate.ColorWithColor (
          Phaser.Display.Color.ValueToColor (this.currentColor),
          Phaser.Display.Color.ValueToColor (targetColor),
          100,
          progress * 100
        );
        const color = Phaser.Display.Color.GetColor (
          interpolatedColor.r,
          interpolatedColor.g,
          interpolatedColor.b
        );
        this.changeSelectEffectColor (color); // 색상 변경 함수 호출
        this.currentColor = targetColor;
      },
      onComplete: () => {
        this.images.forEach (image => {
          if (image.y < -image.displayHeight / 2) {
            image.y += offset * this.images.length;
          }
        });
        this.updateImageSizes ();
        this.isSliding = false; // 슬라이드 종료
      },
    });
  }

  updateImageSizes (device) {
    const centerY = this.scale.height / 2;
    let centerIndex = -1;
    let minDistance = Infinity;

    // 중앙 이미지를 찾고 크기 조정 및 alpha 설정
    this.images.forEach ((image, index) => {
      const distanceFromCenter = Math.abs (centerY - image.y);
      if (distanceFromCenter < minDistance) {
        minDistance = distanceFromCenter;
        centerIndex = index;
      }
      const alpha = this.calculateAlpha (image.y);

      if (distanceFromCenter < this.scale.height / 12) {
        // 중심 이미지의 임계값 설정
        this.tweens.add ({
          targets: image,
          scaleX: 1,
          scaleY: 1,
          alpha: 1,
          duration: 200,
          ease: 'Linear',
          onUpdate: (tween, target) => {
            target.setOrigin (0.5, 0.5);
          },
        });
      } else {
        this.tweens.add ({
          targets: image,
          scaleX: 0.5,
          scaleY: 0.5,
          alpha: alpha,
          duration: 200,
          ease: 'Linear',
          onUpdate: (tween, target) => {
            target.setOrigin (0.5, 0.5);
          },
        });
      }
    });

    // 중심선에 제일 가까운 이미지를 중심으로 이동시키기
    if (centerIndex !== -1 && minDistance >= this.scale.height / 12) {
      const centerImage = this.images[centerIndex];
      const offset = centerY - centerImage.y;

      this.tweens.add ({
        targets: this.images,
        y: `+=${offset}`,
        duration: 400,
        ease: 'Cubic.easeOut',
        onComplete: () => {
          this.updateImageSizes (); // 재귀적으로 호출하여 위치 조정
        },
      });
    } else if (centerIndex !== -1) {
      // 중앙 이미지의 바로 위와 아래 이미지 크기 조정 및 alpha 설정
      const prevIndex =
        (centerIndex - 1 + this.images.length) % this.images.length;
      const nextIndex = (centerIndex + 1) % this.images.length;
      const prevPrevIndex =
        (centerIndex - 2 + this.images.length) % this.images.length;
      const nextNextIndex = (centerIndex + 2) % this.images.length;

      this.tweens.add ({
        targets: [this.images[prevIndex], this.images[nextIndex]],
        scaleX: 0.75,
        scaleY: 0.75,
        alpha: 0.85,
        duration: 200,
        ease: 'Linear',
        onUpdate: (tween, target) => {
          const progress = tween.progress;
          if (target === this.images[prevIndex]) {
            target.setOrigin (0.5, 0.25 + 0.25 * progress); // 0.25에서 0.5로 천천히 변경
          } else {
            target.setOrigin (0.5, 0.75 - 0.25 * progress); // 0.75에서 0.5로 천천히 변경
          }
        },
      });

      this.tweens.add ({
        targets: [this.images[prevPrevIndex], this.images[nextNextIndex]],
        scaleX: 0.5,
        scaleY: 0.5,
        alpha: 0.6,
        duration: 200,
        ease: 'Linear',
        onUpdate: (tween, target) => {
          const progress = tween.progress;
          if (target === this.images[prevPrevIndex]) {
            target.setOrigin (0.5, 0.5 - 0.25 * progress); // 0.5에서 0.25로 천천히 변경
          } else {
            target.setOrigin (0.5, 0.5 + 0.25 * progress); // 0.5에서 0.75로 천천히 변경
          }
        },
        // onComplete: () => {
        //   const correction = device === 'mob'? 1 : 2
        //   this.images[prevPrevIndex].y += 50 * correction; // 위로 100 픽셀 이동
        //   this.images[nextNextIndex].y -= 50 * correction; // 아래로 100 픽셀 이동
        // }
      });
    }
  }

  calculateAlpha (y) {
    const centerY = this.scale.height / 2;
    const distanceFromCenter = Math.abs (centerY - y);
    if (distanceFromCenter < this.scale.height / 12) {
      return 1;
    } else if (distanceFromCenter < this.scale.height / 6) {
      return 0.85;
    } else {
      return 0.6;
    }
  }

  changeSelectEffectColor (color) {
    this.selectEffectTop.setTint (color);
    this.selectEffectTop.setAlpha (0.5); // 위쪽을 반투명하게 설정
    this.selectEffectBottom.setTint (color);
    this.selectEffectBottom.setAlpha (0.5); // 아래쪽을 반투명하게 설정
  }

  resetGameState () {
    this.nodes = null;
    this.nodesClass = null;
    this.combo = 0;
    this.score = 0;
    this.judgementText = null;
    this.isFever = false;
    this.addScore = null;
    this.bgm = null;
    this.pauseTime = 0;
    this.isPause = false;
    this.isGameStarted = false;
    this.onModal = false;
    this.isFocusOut = false;
    this.countdownNumber = 3;
    this.currentIndex = 1;
  }

  load_background_image (device) {
    this.add.image (0, 0, `main_${device}_bg`).setOrigin (0);

    if (device === 'mob') {
      this.add.image (0, 0, `main_${device}_background_cover`).setOrigin (0);
      this.add.image (0, 0, `main_${device}_background_circuit`).setOrigin (0);
      this.add
        .image (0, 0, `main_${device}_background_circuit_cover`)
        .setOrigin (0);
    }
  }

  load_top_bar (device) {
    const correction = device === 'mob' ? 1 : 2;
    const top_bar = this.add
      .image (0, 0, `main_${device}_top_bar`)
      .setOrigin (0)
      .setDepth (2);
    top_bar.x =
      this.positionCalc.xGameCenterCalc (this.game, top_bar) + 7 * correction;
    top_bar.y = 100 * correction;

    const back_button = this.add
      .rectangle (0, 0, 280, 200, 0xffffff)
      .setOrigin (0)
      .setDepth (3)
      .setInteractive ()
      .setAlpha (0.001);
    back_button.x = top_bar.x + 15 * correction;
    back_button.y = top_bar.y + 15 * correction;
    back_button.on ('pointerdown', () => {
      this.scene.stop ('main');
      this.scene.start ('welcome');
    });

    return top_bar;
  }

  load_select_effect_top_image (device) {
    const select_effect_top = this.add
      .image (0, 0, `main_${device}_select_effect`)
      .setOrigin (0, 0)
      .setCrop (
        0,
        0,
        this.textures.get (`main_${device}_select_effect`).getSourceImage ()
          .width,
        this.textures.get (`main_${device}_select_effect`).getSourceImage ()
          .height / 2
      );
    select_effect_top.x = this.positionCalc.xGameCenterCalc (
      this.game,
      select_effect_top
    );
    select_effect_top.y = this.positionCalc.yGameCenterCalc (
      this.game,
      select_effect_top
    );

    return select_effect_top;
  }

  load_select_effect_button_image (device) {
    const select_effect_bottom = this.add
      .image (0, 0, `main_${device}_select_effect`)
      .setOrigin (0, 1)
      .setCrop (
        0,
        this.textures.get (`main_${device}_select_effect`).getSourceImage ()
          .height / 2,
        this.textures.get (`main_${device}_select_effect`).getSourceImage ()
          .width,
        this.textures.get (`main_${device}_select_effect`).getSourceImage ()
          .height / 2
      );
    select_effect_bottom.x = this.positionCalc.xGameCenterCalc (
      this.game,
      select_effect_bottom
    );
    select_effect_bottom.y =
      select_effect_bottom.height +
      this.positionCalc.yGameCenterCalc (this.game, select_effect_bottom);

    return select_effect_bottom;
  }

  load_popup_button (top_bar, device) {
    const correction = device === 'mob' ? 0.5 : 1;
    const pop_up_button = this.add
      .rectangle (0, 0, 280 * correction, 200 * correction, 0x00000)
      .setOrigin (0)
      .setDepth (4)
      .setInteractive ()
      .setAlpha (0.001);
    var gap = device === 'mob' ? 910 : top_bar.width - pop_up_button.width - 10;
    pop_up_button.x = gap;
    pop_up_button.y = top_bar.y + 30 * correction;

    /* POPUP VISIBLE */
    const pop_up_bg = this.add
      .image (0, 0, `main_${device}_popup_bg`)
      .setOrigin (0)
      .setDepth (4)
      .setVisible (false);
    pop_up_bg.x = this.positionCalc.xGameCenterCalc (this.game, pop_up_bg);
    pop_up_bg.y = this.positionCalc.yGameCenterCalc (this.game, pop_up_bg);

    const pop_up_content = this.add
      .image (0, 0, `main_${device}_popup_content`)
      .setOrigin (0)
      .setDepth (4)
      .setVisible (false);
    pop_up_content.x = this.positionCalc.xGameCenterCalc (
      this.game,
      pop_up_content
    );
    pop_up_content.y = pop_up_button.y + 30 * correction;

    const pop_up_close_button = this.add
      .image (0, 0, `main_${device}_popup_close_button`)
      .setOrigin (0)
      .setDepth (5)
      .setVisible (false)
      .setInteractive ();
    var close_button_x = device === 'mob' ? 135 : 90;
    pop_up_close_button.x = pop_up_button.x + close_button_x * correction;
    pop_up_close_button.y = top_bar.y + 75 * correction;

    pop_up_button.on ('pointerdown', () => {
      pop_up_bg.setVisible (true);
      pop_up_content.setVisible (true);
      pop_up_close_button.setVisible (true);

      this.select_area.disableInteractive ();
      this.on_popup = true;
    });

    pop_up_close_button.on ('pointerdown', () => {
      pop_up_bg.setVisible (false);
      pop_up_content.setVisible (false);
      pop_up_close_button.setVisible (false);

      this.select_area.setInteractive ();
      this.on_popup = false;
    });
  }

  load_image_group (device) {
    const centerX = this.scale.width / 2;
    const centerY = this.scale.height / 2;
    const offset = this.scale.height / 4.4;

    const images = [
      this.add
        .image (centerX, centerY - offset * 4, `main_${device}_music_object_2`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY - offset * 3, `main_${device}_music_object_3`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY - offset * 2, `main_${device}_music_object_4`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY - offset, `main_${device}_music_object_5`)
        .setOrigin (0.5, 0.25)
        .setInteractive (),
      this.add
        /* INDEX = 4 */
        .image (centerX, centerY, `main_${device}_music_object_1`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY + offset, `main_${device}_music_object_2`)
        .setOrigin (0.5, 0.75)
        .setInteractive (),
      this.add
        .image (centerX, centerY + offset * 2, `main_${device}_music_object_3`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),

      this.add
        .image (centerX, centerY + offset * 3, `main_${device}_music_object_4`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY + offset * 4, `main_${device}_music_object_5`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
      this.add
        .image (centerX, centerY + offset * 5, `main_${device}_music_object_1`)
        .setOrigin (0.5, 0.5)
        .setInteractive (),
    ];

    return images;
  }

  load_select_area () {
    this.select_area = this.add
      .rectangle (
        this.game.config.width / 2,
        this.game.config.height / 2,
        this.game.config.width * 7 / 8,
        this.game.config.height / 5,
        '0x00000'
      )
      .setOrigin (0.5)
      .setInteractive ()
      .setAlpha (0.0001)
      .setDepth (1000);

    let click_startY; //클릭한 y점

    this.select_area.on ('pointerdown', pointer => {
      click_startY = pointer.y + window.scrollY; // 클릭 지점의 Y 값 기록
      //console.log('Pointer down click_startY at:', click_startY);
    });

    this.select_area.on ('pointerup', pointer => {
      // 스크롤 위치 포함 클릭을 뗀 지점의 Y값 기록
      const click_endY = pointer.y + window.scrollY; //클릭 뗀 y점
      //console.log('Pointer Up click_endY at:', click_endY);

      // 클릭 지점과 클릭을 뗀 지점의 높이 차이 계산
      const click_deltaY = click_endY - click_startY;
      //console.log('Delta Y:', click_deltaY);

      if (click_deltaY <= 1) {
        //console.log('같은 자리 클릭');
        const musicIndex = this.currentIndex - 1;
        this.scene.start ('game', {musicInfo: this.musics[musicIndex], apiKey: this.apiKey});
        fetch (
          'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/updatePlayCount',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify ({id: this.currentIndex}),
          }
        )
          .then (response => response.json ())
          .then (data => {
            if (data.success) {
              this.scene.stop ('main');
            } else {
              console.error ('Failed to update play count:', data.error);
            }
          })
          .catch (error => console.error ('Error:', error));
      } else {
        //console.log('스크롤');
      }
    });

    return this.select_area;
  }

  load_main_background_image (device) {
    this.add.image (0, 0, `main_${device}_bg`).setOrigin (0);

    if (device === 'mob') {
      this.add.image (0, 0, `main_${device}_background_cover`).setOrigin (0);
      this.add.image (0, 0, `main_${device}_background_circuit`).setOrigin (0);
      this.add
        .image (0, 0, `main_${device}_background_circuit_cover`)
        .setOrigin (0);
    }
  }

  load_start_direction_motion (device) {
    this.anims.create ({
      key: 'direction_moving',
      frames: this.anims.generateFrameNames (
        `main_${device}_direction_moving_motion`,
        {
          prefix: 'sprite',
          start: 1,
          end: 49,
          zeroPad: 1,
        }
      ),
      frameRate: 20,
      repeat: -1,
    });

    const sprite = this.add
      .sprite (0, 0, `main_${device}_direction_moving_motion`)
      .play ('direction_moving')
      .setDepth (0)
      .setScale (2);
    sprite.x = this.game.config.width / 2;
    sprite.y = this.game.config.height / 2;

    sprite.on ('animationupdate', (animation, frame) => {
      if (frame.index === 45) {
        sprite.anims.stop ();
        sprite.anims.play ('direction_moving', true);
      }
    });

    sprite.setInteractive ();
  }

  load_direction_key_function () {
    this.cursors = this.input.keyboard.createCursorKeys ();

    this.input.keyboard.on ('keydown-UP', this.nextImage, this);
    this.input.keyboard.on ('keydown-DOWN', this.prevImage, this);

    this.input.keyboard.on ('keydown-ENTER', () => {
      this.simulatePointerDownUp ();
    });
    this.input.keyboard.on ('keydown-SPACE', () => {
      this.simulatePointerDownUp ();
    });
  }

  simulatePointerDownUp () {
    const pointer = this.input.activePointer;
    this.select_area.emit ('pointerdown', pointer);
    this.select_area.emit ('pointerup', pointer);
  }
}

export default Main;
