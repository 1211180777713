import Phaser from 'phaser';

/* CONNECT MODULE */
import Coordinate from '../theme/Coordinate';
import GameUI from '../interface/GameUI';

/* WEB VIEW ASSETS */
import game_web_bg from '../assets/img/game/web_view/background.png';
import game_web_node_D from '../assets/img/game/web_view/node_d.png';
import game_web_node_F from '../assets/img/game/web_view/node_f.png';
import game_web_node_J from '../assets/img/game/web_view/node_j.png';
import game_web_node_K from '../assets/img/game/web_view/node_k.png';
import game_web_key_d_button
  from '../assets/img/game/web_view/key_d_button.png';
import game_web_key_f_button
  from '../assets/img/game/web_view/key_f_button.png';
import game_web_key_j_button
  from '../assets/img/game/web_view/key_j_button.png';
import game_web_key_k_button
  from '../assets/img/game/web_view/key_k_button.png';
import game_web_under_bar_frame
  from '../assets/img/game/web_view/under_bar_frame.png';
import game_web_pause_button
  from '../assets/img/game/web_view/pause_button.png';
import game_web_popup_button
  from '../assets/img/game/web_view/popup_button.png';
import game_web_effect_line from '../assets/img/game/web_view/effect_line.png';
import game_web_hit_unit from '../assets/img/game/web_view/hit_unit.png';
import game_web_effect_route
  from '../assets/img/game/web_view/effect_route.png';
import game_web_top_bar_frame
  from '../assets/img/game/web_view/top_bar_frame.png';
import game_web_add_score_panel
  from '../assets/img/game/web_view/add_score_panel.png';
import game_web_combo_gauge from '../assets/img/game/web_view/combo_gauge.png';
import game_web_pause_bg
  from '../assets/img/game/web_view/pause/background.png';
import game_web_pause_frame from '../assets/img/game/web_view/pause/frame.png';
import game_web_pause_back_button
  from '../assets/img/game/web_view/pause/back_button.png';
import game_web_pause_suspension_button
  from '../assets/img/game/web_view/pause/suspension_button.png';
import game_web_pause_light_button
  from '../assets/img/game/web_view/pause/light_button.png';
import game_web_popup_bg
  from '../assets/img/game/web_view/popup/background.png';
import game_web_popup_close_button
  from '../assets/img/game/web_view/popup/close_button.png';
import game_web_popup_content
  from '../assets/img/game/web_view/popup/content.png';
import game_web_top_bar_gauge
  from '../assets/img/game/web_view/top_bar_gauge.png';
import game_web_game_scene_title_1
  from '../assets/img/game/web_view/music_param/game_1_title.png';
import game_web_game_scene_title_2
  from '../assets/img/game/web_view/music_param/game_2_title.png';
import game_web_game_scene_title_3
  from '../assets/img/game/web_view/music_param/game_3_title.png';
import game_web_game_scene_title_4
  from '../assets/img/game/web_view/music_param/game_4_title.png';
import game_web_game_scene_title_5
  from '../assets/img/game/web_view/music_param/game_5_title.png';
import game_web_zero_score from '../assets/img/game/web_view/zero_score.png';
import game_web_top_bar_gauge_fever
  from '../assets/img/game/web_view/game_web_top_bar_gauge_fever.png';
import game_web_countdown_1
  from '../assets/img/game/web_view/countdown/countdown_1.png';
import game_web_countdown_2
  from '../assets/img/game/web_view/countdown/countdown_2.png';
import game_web_countdown_3
  from '../assets/img/game/web_view/countdown/countdown_3.png';
import game_web_pad_frame from '../assets/img/game/web_view/pad_frame.png';
import game_web_route_line from '../assets/img/game/web_view/route_line.png';
import game_web_zero_combo from '../assets/img/game/web_view/zero_combo.png';
import game_web_countdown_pad_cover
  from '../assets/img/game/web_view/countdown/pad_cover.png';

/* MOBILE VIEW ASSETS */
import game_mob_bg from '../assets/img/game/mobile_view/background.png';
import game_mob_bg_cover
  from '../assets/img/game/mobile_view/background_cover.png';
import game_mob_circuit from '../assets/img/game/mobile_view/circuit.png';
import game_mob_key_d_button
  from '../assets/img/game/mobile_view/key_d_button.png';
import game_mob_key_f_button
  from '../assets/img/game/mobile_view/key_f_button.png';
import game_mob_key_j_button
  from '../assets/img/game/mobile_view/key_j_button.png';
import game_mob_key_k_button
  from '../assets/img/game/mobile_view/key_k_button.png';
import game_mob_hit_unit from '../assets/img/game/mobile_view/hit_unit.png';
import game_mob_effect_line
  from '../assets/img/game/mobile_view/effect_line.png';
import game_mob_effect_route
  from '../assets/img/game/mobile_view/effect_route.png';
import game_mob_top_bar_frame
  from '../assets/img/game/mobile_view/top_bar_frame.png';
import game_mob_combo_gauge
  from '../assets/img/game/mobile_view/combo_gauge.png';
import game_mob_add_score_panel
  from '../assets/img/game/mobile_view/add_score_panel.png';
import game_mob_under_bar_frame
  from '../assets/img/game/mobile_view/under_bar_frame.png';
import game_mob_popup_button
  from '../assets/img/game/mobile_view/popup_button.png';
import game_mob_pause_button
  from '../assets/img/game/mobile_view/pause_button.png';
import game_mob_logo from '../assets/img/game/mobile_view/logo.png';
import game_mob_pause_bg
  from '../assets/img/game/mobile_view/pause/background.png';
import game_mob_pause_back_button
  from '../assets/img/game/mobile_view/pause/back_button.png';
import game_mob_pause_frame
  from '../assets/img/game/mobile_view/pause/frame.png';
import game_mob_pause_light_button
  from '../assets/img/game/mobile_view/pause/light_button.png';
import game_mob_pause_suspension_button
  from '../assets/img/game/mobile_view/pause/suspension_button.png';
import game_mob_popup_bg
  from '../assets/img/game/mobile_view/popup/background.png';
import game_mob_popup_content
  from '../assets/img/game/mobile_view/popup/content.png';
import game_mob_popup_close_button
  from '../assets/img/game/mobile_view/popup/close_button.png';
import game_mob_top_bar_gauge
  from '../assets/img/game/mobile_view/top_bar_gauge.png';
import game_mob_game_scene_title_1
  from '../assets/img/game/mobile_view/music_param/game_1_title.png';
import game_mob_game_scene_title_2
  from '../assets/img/game/mobile_view/music_param/game_2_title.png';
import game_mob_game_scene_title_3
  from '../assets/img/game/mobile_view/music_param/game_3_title.png';
import game_mob_game_scene_title_4
  from '../assets/img/game/mobile_view/music_param/game_4_title.png';
import game_mob_game_scene_title_5
  from '../assets/img/game/mobile_view/music_param/game_5_title.png';
import game_mob_zero_score from '../assets/img/game/mobile_view/zero_score.png';
import game_mob_top_bar_gauge_fever
  from '../assets/img/game/mobile_view/game_mob_top_bar_gauge_fever.png';
import game_mob_countdown_1
  from '../assets/img/game/mobile_view/countdown/countdown_1.png';
import game_mob_countdown_2
  from '../assets/img/game/mobile_view/countdown/countdown_2.png';
import game_mob_countdown_3
  from '../assets/img/game/mobile_view/countdown/countdown_3.png';
import game_mob_pad_frame from '../assets/img/game/mobile_view/pad_frame.png';
import game_mob_route_line from '../assets/img/game/mobile_view/route_line.png';
import game_mob_zero_combo from '../assets/img/game/mobile_view/zero_combo.png';

/* LOAD WEB MOTION */
import game_web_route_motion
  from '../assets/animation/game/game_web_route_motion.png';
import game_web_middle_star_motion
  from '../assets/animation/game/game_web_middle_star_motion.png';
import game_web_small_star_motion
  from '../assets/animation/game/game_web_small_star_motion.png';
import game_web_fever_motion
  from '../assets/animation/game/game_web_fever_motion.png';
import welcome_web_circuit_motion
  from '../assets/animation/welcome/welcome_web_circuit_motion.png';
import game_web_perfect_motion
  from '../assets/animation/game/judgement/game_web_perfect_motion.png';
import game_web_great_motion
  from '../assets/animation/game/judgement/game_web_great_motion.png';
import game_web_good_motion
  from '../assets/animation/game/judgement/game_web_good_motion.png';
import game_web_miss_motion
  from '../assets/animation/game/judgement/game_web_miss_motion.png';
import game_web_early_motion
  from '../assets/animation/game/judgement/game_web_early_motion.png';
import game_web_game_scene_artist_1
  from '../assets/animation/game/artist/game_web_game_scene_artist_1.png';
import game_web_game_scene_artist_2
  from '../assets/animation/game/artist/game_web_game_scene_artist_2.png';
import game_web_game_scene_artist_3
  from '../assets/animation/game/artist/game_web_game_scene_artist_3.png';
import game_web_game_scene_artist_4
  from '../assets/animation/game/artist/game_web_game_scene_artist_4.png';
import game_web_game_scene_artist_5
  from '../assets/animation/game/artist/game_web_game_scene_artist_5.png';

/* LOAD MOBILE MOTION */
import game_mob_route_motion
  from '../assets/animation/game/game_mob_route_motion.png';
import game_mob_middle_star_motion
  from '../assets/animation/game/game_mob_middle_star_motion.png';
import game_mob_small_star_motion
  from '../assets/animation/game/game_mob_small_star_motion.png';
import game_mob_fever_motion
  from '../assets/animation/game/game_mob_fever_motion.png';
import welcome_mob_circuit_motion
  from '../assets/animation/welcome/welcome_mob_circuit_motion.png';
import game_mob_perfect_motion
  from '../assets/animation/game/judgement/game_mob_perfect_motion.png';
import game_mob_great_motion
  from '../assets/animation/game/judgement/game_mob_great_motion.png';
import game_mob_good_motion
  from '../assets/animation/game/judgement/game_mob_good_motion.png';
import game_mob_miss_motion
  from '../assets/animation/game/judgement/game_mob_miss_motion.png';
import game_mob_early_motion
  from '../assets/animation/game/judgement/game_mob_early_motion.png';
import game_mob_game_scene_artist_1
  from '../assets/animation/game/artist/game_mob_game_scene_artist_1.png';
import game_mob_game_scene_artist_2
  from '../assets/animation/game/artist/game_mob_game_scene_artist_2.png';
import game_mob_game_scene_artist_3
  from '../assets/animation/game/artist/game_mob_game_scene_artist_3.png';
import game_mob_game_scene_artist_4
  from '../assets/animation/game/artist/game_mob_game_scene_artist_4.png';
import game_mob_game_scene_artist_5
  from '../assets/animation/game/artist/game_mob_game_scene_artist_5.png';
import game_mob_countdown_pad_cover
  from '../assets/img/game/mobile_view/countdown/pad_cover.png';

/* Module */
import NodeManager from '../node/NodeManager';
import KeyboardEvent from '../input/KeyboardEvent';
import Countdown from '../component/Countdown';
import Combo from '../component/Combo';
import Fever from '../component/Fever';
import Score from '../component/Score';
import Judgement from '../component/Judgement';
import MobileTouchEvent from '../input/MobileTouchEvent';
import ValidateDevice from '../theme/ValidateDevice';
import PositionCalc from '../theme/PositionCalc';
import FontFaceObserver from 'fontfaceobserver';

/* Load Temprory Image */
import ComboToImage from '../converter/ComboToImage';
import combo_text from '../assets/img/game/combo/temp_combo_text.png';

import early from '../assets/img/game/judgement/early.png';
import good from '../assets/img/game/judgement/good.png';
import great from '../assets/img/game/judgement/great.png';
import miss from '../assets/img/game/judgement/miss.png';
import perfect from '../assets/img/game/judgement/perfect.png';

// /* dev. temp assets */
// import Music from '../music_info/Music';
// // import game1_thumbnail from "../assets/img/main/temp_game1_thumbnail.png"
// import game1_music from '../assets/music/Finding_home.mp3';
// import game1_node from '../assets/textNode/Finding_home.txt';

export default class Game extends Phaser.Scene {
  init (data) {
    /* Game info received from 'Main' */
    this.musicInfo = data.musicInfo;
    this.apiKey = 'anothersunday'
    // console.log(this.musicInfo)
    // /* dev. temp assets */
    // this.musicInfo = new Music (
    //   1,
    //   'Finding Home',
    //   'cadejo|J,Clap|Ail Kim',
    //   game1_music,
    //   game1_node
    // );
  }

  constructor () {
    super ('game');

    /* Game node object */
    this.nodesClass = [];
    this.nodes = [];

    /* Game score */
    this.combo = 0;
    this.comboPad = '0000000';
    this.score = 0;
    this.scorePad = '0000000000';
    this.judgementText = null;
    this.isFever = false;
    this.addScore = null;
    this.isFirstJudgement = true;
    this.isFirstComboUpdate = true;

    /* Game bgm */
    this.bgm = null;
    this.pauseTime = 0;

    /* Pause */
    this.isPause = false;
    this.isGameStarted = false;
    this.on_popup = false;

    this.countdownNumber = 3;
    const device = this.isMobile ? 'mob' : 'web';

    /* Module */
    this.coordinate = new Coordinate ();
    this.validateDevice = new ValidateDevice (this);
    this.positionCalc = new PositionCalc ();
    this.gameUI = new GameUI (this);
    this.touchEvents = new MobileTouchEvent (this);
    this.keyboardEvent = new KeyboardEvent (this, device);

    this.comboModule = new Combo (this);
    this.feverModule = new Fever (this);
    this.scoreModule = new Score (this);
    this.judgementModule = new Judgement (this);
    this.comboToImage = new ComboToImage (this);
  }

  preload () {
    this.createProgressBar();
    /* When restart game, before game score, combo, judgement are reset */
    this.resetGameState ();

    /* Load the node info text file*/
    this.load.text ('nodeFile', this.musicInfo.nodeFilePath);

    /* Game setting */
    this.speed = window.game.config.height * 3 / 5 / 60;

    /* dev. temprory keypad image */
    this.load.image ('combo_text', combo_text);
    this.load.image ('great', great);
    this.load.image ('perfect', perfect);
    this.load.image ('good', good);
    this.load.image ('early', early);
    this.load.image ('miss', miss);

    this.isMobile = this.validateDevice.isMobile ();

    if (this.isMobile) {
      /* LOAD MOBILE VIEW ASSETS */
      this.load.image ('game_mob_bg', game_mob_bg);
      this.load.image ('game_mob_bg_cover', game_mob_bg_cover);
      this.load.image ('game_mob_circuit', game_mob_circuit);
      this.load.image ('game_mob_route_line', game_mob_route_line);
      this.load.image ('game_mob_key_d_button', game_mob_key_d_button);
      this.load.image ('game_mob_key_f_button', game_mob_key_f_button);
      this.load.image ('game_mob_key_j_button', game_mob_key_j_button);
      this.load.image ('game_mob_key_k_button', game_mob_key_k_button);
      this.load.image ('game_mob_hit_unit', game_mob_hit_unit);
      this.load.image ('game_mob_effect_line', game_mob_effect_line);
      this.load.image ('game_mob_effect_route', game_mob_effect_route);
      this.load.image ('game_mob_top_bar_frame', game_mob_top_bar_frame);
      this.load.image ('game_mob_combo_gauge', game_mob_combo_gauge);
      this.load.image ('game_mob_add_score_panel', game_mob_add_score_panel);
      this.load.image ('game_mob_under_bar_frame', game_mob_under_bar_frame);
      this.load.image ('game_mob_popup_button', game_mob_popup_button);
      this.load.image ('game_mob_pause_button', game_mob_pause_button);
      this.load.image ('game_mob_logo', game_mob_logo);
      this.load.image ('game_mob_zero_score', game_mob_zero_score);
      this.load.image ('game_mob_pause_bg', game_mob_pause_bg);
      this.load.image (
        'game_mob_pause_back_button',
        game_mob_pause_back_button
      );
      this.load.image ('game_mob_pause_frame', game_mob_pause_frame);
      this.load.image (
        'game_mob_pause_light_button',
        game_mob_pause_light_button
      );
      this.load.image (
        'game_mob_pause_suspension_button',
        game_mob_pause_suspension_button
      );
      this.load.image ('game_web_node_D', game_web_node_D);
      this.load.image ('game_web_node_F', game_web_node_F);
      this.load.image ('game_web_node_J', game_web_node_J);
      this.load.image ('game_web_node_K', game_web_node_K);
      this.load.image ('game_mob_popup_bg', game_mob_popup_bg);
      this.load.image ('game_mob_popup_content', game_mob_popup_content);
      this.load.image (
        'game_mob_popup_close_button',
        game_mob_popup_close_button
      );
      this.load.image ('game_mob_top_bar_gauge', game_mob_top_bar_gauge);
      if(this.musicInfo.id===1){
        this.load.image (
          'game_mob_game_scene_title_1',
          game_mob_game_scene_title_1
        );
        this.load.atlas (
          'game_mob_game_scene_artist_1',
          game_mob_game_scene_artist_1,
          '../assets/animation/game/artist/game_mob_game_scene_artist_1.json'
        );
      }
      if(this.musicInfo.id===2){
        this.load.image (
          'game_mob_game_scene_title_2',
          game_mob_game_scene_title_2
        );
        this.load.atlas (
          'game_mob_game_scene_artist_2',
          game_mob_game_scene_artist_2,
          '../assets/animation/game/artist/game_mob_game_scene_artist_2.json'
        );
      }
      if(this.musicInfo.id===3){
        this.load.image (
          'game_mob_game_scene_title_3',
          game_mob_game_scene_title_3
        );
        this.load.atlas (
          'game_mob_game_scene_artist_3',
          game_mob_game_scene_artist_3,
          '../assets/animation/game/artist/game_mob_game_scene_artist_3.json'
        );
      }
      if(this.musicInfo.id===4){
        this.load.image (
          'game_mob_game_scene_title_4',
          game_mob_game_scene_title_4
        );
        this.load.atlas (
          'game_mob_game_scene_artist_4',
          game_mob_game_scene_artist_4,
          '../assets/animation/game/artist/game_mob_game_scene_artist_4.json'
        );
      }
      if(this.musicInfo.id===5){
        this.load.image (
          'game_mob_game_scene_title_5',
          game_mob_game_scene_title_5
        );

      this.load.atlas (
        'game_mob_game_scene_artist_5',
        game_mob_game_scene_artist_5,
        '../assets/animation/game/artist/game_mob_game_scene_artist_5.json'
      );
      }
      this.load.image (
        'game_mob_top_bar_gauge_fever',
        game_mob_top_bar_gauge_fever
      );
      this.load.image ('game_mob_countdown_1', game_mob_countdown_1);
      this.load.image ('game_mob_countdown_2', game_mob_countdown_2);
      this.load.image ('game_mob_countdown_3', game_mob_countdown_3);
      this.load.image (
        'game_mob_countdown_pad_cover',
        game_mob_countdown_pad_cover
      );
      this.load.image ('game_mob_route_line', game_mob_route_line);
      this.load.image ('game_mob_pad_frame', game_mob_pad_frame);
      this.load.image ('game_mob_zero_combo', game_mob_zero_combo);

      this.load.atlas (
        'game_mob_route_motion',
        game_mob_route_motion,
        '../assets/animation/game/game_mob_route_motion.json'
      );
      this.load.atlas (
        'game_mob_middle_star_motion',
        game_mob_middle_star_motion,
        '../assets/animation/game/game_mob_middle_star_motion.json'
      );
      this.load.atlas (
        'game_mob_small_star_motion',
        game_mob_small_star_motion,
        '../assets/animation/game/game_mob_small_star_motion.json'
      );
      this.load.atlas (
        'game_mob_fever_motion',
        game_mob_fever_motion,
        '../assets/animation/game/game_mob_fever_motion.json'
      );
      this.load.atlas (
        'welcome_mob_circuit_motion',
        welcome_mob_circuit_motion,
        '../assets/animation/welcome/welcome_mob_circuit_motion.json'
      );
      this.load.atlas (
        'game_mob_perfect_motion',
        game_mob_perfect_motion,
        '../assets/animation/game/judgement/game_mob_perfect_motion.json'
      );
      this.load.atlas (
        'game_mob_great_motion',
        game_mob_great_motion,
        '../assets/animation/game/judgement/game_mob_great_motion.json'
      );
      this.load.atlas (
        'game_mob_good_motion',
        game_mob_good_motion,
        '../assets/animation/game/judgement/game_mob_good_motion.json'
      );
      this.load.atlas (
        'game_mob_early_motion',
        game_mob_early_motion,
        '../assets/animation/game/judgement/game_mob_early_motion.json'
      );
      this.load.atlas (
        'game_mob_miss_motion',
        game_mob_miss_motion,
        '../assets/animation/game/judgement/game_mob_miss_motion.json'
      );

    } else {
      /* LOAD WEB VIEW ASSETS*/
      this.load.image ('game_web_bg', game_web_bg);
      this.load.image ('game_web_node_D', game_web_node_D);
      this.load.image ('game_web_node_F', game_web_node_F);
      this.load.image ('game_web_node_J', game_web_node_J);
      this.load.image ('game_web_node_K', game_web_node_K);
      this.load.image ('game_web_key_d_button', game_web_key_d_button);
      this.load.image ('game_web_key_f_button', game_web_key_f_button);
      this.load.image ('game_web_key_j_button', game_web_key_j_button);
      this.load.image ('game_web_key_k_button', game_web_key_k_button);
      this.load.image ('game_web_under_bar_frame', game_web_under_bar_frame);
      this.load.image ('game_web_pause_button', game_web_pause_button);
      this.load.image ('game_web_popup_button', game_web_popup_button);
      this.load.image ('game_web_effect_line', game_web_effect_line);
      this.load.image ('game_web_hit_unit', game_web_hit_unit);
      this.load.image ('game_web_effect_route', game_web_effect_route);
      this.load.image ('game_web_top_bar_frame', game_web_top_bar_frame);
      this.load.image ('game_web_add_score_panel', game_web_add_score_panel);
      this.load.image ('game_web_combo_gauge', game_web_combo_gauge);
      this.load.image ('game_web_pause_bg', game_web_pause_bg);
      this.load.image ('game_web_pause_frame', game_web_pause_frame);
      this.load.image ('game_web_zero_score', game_web_zero_score);
      this.load.image ('game_web_zero_combo', game_web_zero_combo);
      this.load.image (
        'game_web_pause_back_button',
        game_web_pause_back_button
      );
      this.load.image (
        'game_web_pause_suspension_button',
        game_web_pause_suspension_button
      );
      this.load.image (
        'game_web_pause_light_button',
        game_web_pause_light_button
      );
      this.load.image ('game_web_popup_bg', game_web_popup_bg);
      this.load.image (
        'game_web_popup_close_button',
        game_web_popup_close_button
      );
      this.load.image ('game_web_popup_content', game_web_popup_content);
      this.load.image ('game_web_top_bar_gauge', game_web_top_bar_gauge);
      if(this.musicInfo.id === 1){
        this.load.image (
          'game_web_game_scene_title_1',
          game_web_game_scene_title_1
        );
        this.load.atlas (
          'game_web_game_scene_artist_1',
          game_web_game_scene_artist_1,
          '../assets/animation/game/artist/game_web_game_scene_artist_1.json'
        );
      }
      if(this.musicInfo.id === 2){
        this.load.image (
          'game_web_game_scene_title_2',
          game_web_game_scene_title_2
        );
        this.load.atlas (
          'game_web_game_scene_artist_2',
          game_web_game_scene_artist_2,
          '../assets/animation/game/artist/game_web_game_scene_artist_2.json'
        );
      }
      if(this.musicInfo.id === 3){
        this.load.image (
          'game_web_game_scene_title_3',
          game_web_game_scene_title_3
        );
        this.load.atlas (
          'game_web_game_scene_artist_3',
          game_web_game_scene_artist_3,
          '../assets/animation/game/artist/game_web_game_scene_artist_3.json'
        );
      }
      if(this.musicInfo.id === 4){
        this.load.image (
          'game_web_game_scene_title_4',
          game_web_game_scene_title_4
        );
        this.load.atlas (
          'game_web_game_scene_artist_4',
          game_web_game_scene_artist_4,
          '../assets/animation/game/artist/game_web_game_scene_artist_4.json'
        );
      }
      if(this.musicInfo.id === 5){
        this.load.image (
          'game_web_game_scene_title_5',
          game_web_game_scene_title_5
        );
        this.load.atlas (
          'game_web_game_scene_artist_5',
          game_web_game_scene_artist_5,
          '../assets/animation/game/artist/game_web_game_scene_artist_5.json'
        );
      }
      this.load.image (
        'game_web_top_bar_gauge_fever',
        game_web_top_bar_gauge_fever
      );
      this.load.image ('game_web_countdown_1', game_web_countdown_1);
      this.load.image ('game_web_countdown_2', game_web_countdown_2);
      this.load.image ('game_web_countdown_3', game_web_countdown_3);
      this.load.image (
        'game_web_countdown_pad_cover',
        game_web_countdown_pad_cover
      );
      this.load.image ('game_web_route_line', game_web_route_line);
      this.load.image ('game_web_pad_frame', game_web_pad_frame);
      this.load.atlas (
        'game_web_route_motion',
        game_web_route_motion,
        '../assets/animation/game/game_web_route_motion.json'
      );
      this.load.atlas (
        'game_web_middle_star_motion',
        game_web_middle_star_motion,
        '../assets/animation/game/game_web_middle_star_motion.json'
      );
      this.load.atlas (
        'game_web_small_star_motion',
        game_web_small_star_motion,
        '../assets/animation/game/game_web_small_star_motion.json'
      );
      this.load.atlas (
        'game_web_fever_motion',
        game_web_fever_motion,
        '../assets/animation/game/game_web_fever_motion.json'
      );
      this.load.atlas (
        'welcome_web_circuit_motion',
        welcome_web_circuit_motion,
        '../assets/animation/welcome/welcome_web_circuit_motion.json'
      );
      this.load.atlas (
        'game_web_perfect_motion',
        game_web_perfect_motion,
        '../assets/animation/game/judgement/game_web_perfect_motion.json'
      );
      this.load.atlas (
        'game_web_great_motion',
        game_web_great_motion,
        '../assets/animation/game/judgement/game_web_great_motion.json'
      );
      this.load.atlas (
        'game_web_good_motion',
        game_web_good_motion,
        '../assets/animation/game/judgement/game_web_good_motion.json'
      );
      this.load.atlas (
        'game_web_early_motion',
        game_web_early_motion,
        '../assets/animation/game/judgement/game_web_early_motion.json'
      );
      this.load.atlas (
        'game_web_miss_motion',
        game_web_miss_motion,
        '../assets/animation/game/judgement/game_web_miss_motion.json'
      );
    }
  }

  async create() {
    await this.loadAndPlayMusic(this.musicInfo.musicPath, this.apiKey);

    const device = this.isMobile ? 'mob' : 'web';

    this.load_game_scene_bg_image (device);
    this.load_judgement_motion (device);

    /* LOAD KEY PAD FRAME AND UNITS */
    this.load_game_key_pad_frame (device);

    /* LOAD TOP BAR FRAME AND UNITS */
    this.load_game_top_bar (device);

    /* LOAD UNDER BAR FRAME AND UNITS*/
    this.load_game_under_bar (device);
    if (device === 'mob') {
      this.load_mobile_touch_area (device);
    }

    /* PAUSE BUTTON */
    this.apply_pause_button_function (device);

    /* POPUP BUTTON */
    this.apply_popup_button_function (device);

    /* KEY PRESS EFFECT */
    this.load_key_press_effect (device);

    if (device === 'web') {
      this.load_pause_key_esc ();
    }

    this.game_start (device);

    // this.set_up_layout_frame();
    // this.set_up_next_page ();
  }

  update () {
    const correction = this.isMobile ? 1 : 2;
    if (!this.isPause && this.isGameStarted) {
      this.nodeManager.nodeSlider ();

      /* REAL TIME UPDATE FOR MISS JUDGEMENT */
      this.judgementModule.updateJudgementImage ();
      this.feverModule.setFever ();
      this.scoreModule.updateScore ();
      this.comboModule.updateCombo ();
      this.comboModule.setComboVisible ();
    }
  }

 
  async loadAndPlayMusic(url, apiKey) {
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': apiKey
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const audioContext = this.sound.context;
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Add the decoded audio buffer to the cache
      this.cache.audio.add('bgm', audioBuffer); // 수정된 부분
  
      // Now create a sound instance using the cached audio
      this.bgm = this.sound.add('bgm', { loop: false }); // 수정된 부분
    } catch (error) {
      console.error('Failed to load and play music file:', error);
    }
  }

async loadMusicFile(url, apiKey) {
  try {
    const response = await fetch(url, {
      headers: {
        'x-api-key': apiKey
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl;
  } catch (error) {
    console.error('Failed to load music file:', error);
    throw error;
  }
}

  resetGameState () {
    this.nodes = null;
    this.nodesClass = null;
    this.combo = 0;
    this.score = 0;
    this.judgementText = null;
    this.isFever = false;
    this.addScore = null;
    this.bgm = null;
    this.pauseTime = 0;
    this.isPause = false;
    this.isGameStarted = false;
    this.onModal = false;
    this.isFocusOut = false;
    this.countdownNumber = 3;
    this.currentIndex = 1;
    this.isFirstJudgement = true;
    this.isFirstComboUpdate = true;
  }

  setIsPauseTrue () {
    this.isPause = true;
  }

  setIsPauseFalse () {
    this.isPause = false;
  }

  load_game_scene_bg_image (device) {
    /* BACKGROUND DEPTH 0 */
    const correction = device === 'mob' ? 1 : 2;
    var gap = device === 'mob' ? -5 : -9;
    this.add.image (gap, 0, `game_${device}_bg`).setOrigin (0).setDepth (0);
    this.route_line = this.add
      .image (0, 0, `game_${device}_route_line`)
      .setOrigin (0)
      .setDepth (2);
    this.route_line.x =
      this.positionCalc.xGameCenterCalc (this.game, this.route_line) - 2;

    var frame_x = device === 'mob' ? 0 : 14;
    this.pad_frame = this.add
      .image (15, 0, `game_${device}_pad_frame`)
      .setOrigin (0)
      .setDepth (4);
    this.pad_frame.x += frame_x;
    this.pad_frame.y = this.game.config.height * 7 / 10;

    // const spirte_count = device === 'mob'? 36 : 72;
    // /* CIRCUIT MOTION */
    // this.anims.create ({
    //   key: 'welcome_circuit_motion',
    //   frames: this.anims.generateFrameNames (`welcome_${device}_circuit_motion`, {
    //     prefix: 'sprite',
    //     start: 1,
    //     end: spirte_count,
    //     zeroPad: 1,
    //   }),
    //   frameRate: 20,
    //   repeat: -1,
    // });

    // this.circuit_motion = this.add
    //   .sprite (0, 0, `welcome_${device}_circuit_motion`)
    //   .play ('welcome_circuit_motion')
    //   .setDepth (0)
    //   .setOrigin (0)
    //   .setScale (4);

    // this.circuit_motion.x = this.game.config.width / 2;
    // this.circuit_motion.y = this.game.config.height / 2;
  }

  load_mobile_touch_area () {
    const gameKeys = ['d', 'f', 'j', 'k'];
    const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00];
    gameKeys.forEach ((gameKey, i) => {
      const width = this.positionCalc.xFractionalPosition (this.game, 1, 4);
      const height = this.under_bar_frame.y - this.judgementLine.y;
      const xPos = this.positionCalc.xFractionalPosition (this.game, i, 4);
      const yPos = this.judgementLine.y + height;

      this[`key_${gameKey}_touch_area`] = this.add
        .rectangle (xPos, yPos, width, height, colors[i])
        .setOrigin (0, 1)
        .setDepth (7)
        .setInteractive ()
        .setAlpha (0.001);
    });
  }

  load_game_key_pad_frame (device) {
    if (device === 'mob') {
      this.key_d_button = this.gameUI.loadKeyPadImage ('d', true);
      this.key_d_button.y -= 55;
      this.key_f_button = this.gameUI.loadKeyPadImage ('f', true);
      this.key_f_button.y -= 55;
      this.key_j_button = this.gameUI.loadKeyPadImage ('j', true);
      this.key_j_button.y -= 55;
      this.key_k_button = this.gameUI.loadKeyPadImage ('k', true);
      this.key_k_button.y -= 70;
    } else {
      this.key_d_button = this.gameUI.loadKeyPadImage ('d', false);
      this.key_f_button = this.gameUI.loadKeyPadImage ('f', false);
      this.key_j_button = this.gameUI.loadKeyPadImage ('j', false);
      this.key_k_button = this.gameUI.loadKeyPadImage ('k', false);
      this.key_k_button.y -= 30;
    }
    this.judgementLine = this.gameUI.setupJudgementLine (device);
  }

  load_game_top_bar (device) {
    /* TOP BAR UNIT DEPTH 1 */
    const correction = device === 'mob' ? 1 : 2;
    const musicIndex = this.musicInfo.id;

    /* TITLE */
    this.game_title = this.add
      .image (0, 0, `game_${device}_game_scene_title_${musicIndex}`)
      .setOrigin (0)
      .setDepth (6);
    this.game_title.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.game_title
    );
    this.game_title.y =
      this.positionCalc.yFractionalPosition (this.game, 1, 17) -
      100 * correction;

    this.top_game_gauge = this.add
      .image (0, 0, `game_${device}_top_bar_gauge`)
      .setOrigin (0)
      .setDepth (6);
    this.top_game_gauge.x =
      this.positionCalc.xGameCenterCalc (this.game, this.top_game_gauge) +
      5 * correction;
    this.top_game_gauge.y =
      this.game_title.y + this.top_game_gauge.height + 110 * correction;

    this.top_fever_gauge = this.add
      .image (0, 0, `game_${device}_top_bar_gauge_fever`)
      .setOrigin (0)
      .setVisible (false)
      .setDepth (8);
    this.top_fever_gauge.x = this.top_game_gauge.x - 5 * correction;
    this.top_fever_gauge.y = this.top_game_gauge.y;

    /* ARTIST MOTION */
    this.anims.create ({
      key: `artist_${musicIndex}_motion`,
      frames: this.anims.generateFrameNames (
        `game_${device}_game_scene_artist_${musicIndex}`,
        {
          prefix: 'sprite',
          start: 1,
          end: 96,
          zeroPad: 1,
        }
      ),
      frameRate: 20,
      repeat: -1,
    });

    this.artist_motion = this.add
      .sprite (0, 0, `game_${device}_game_scene_artist_${musicIndex}`)
      .play (`artist_${musicIndex}_motion`)
      .setDepth (7)
      .setOrigin (0)
      .setScale (2);

    this.artist_motion.x = this.artist_motion.width + 1.5 * correction;
    this.artist_motion.y = 185 * correction;

    /* COMBO */
    this.top_bar_combo = this.add
      .text (0, 0, this.comboPad, {
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff',
      })
      .setOrigin (0)
      .setFontSize (40 * correction)
      .setDepth (9)
      .setVisible (false);
    this.top_bar_combo.x = this.top_game_gauge.width - 165 * correction + 6 * correction;
    this.top_bar_combo.y =
      this.top_game_gauge.height + this.top_game_gauge.y - 65 * correction;

    this.comboImage = this.add
      .image (0, 0, `game_${device}_zero_combo`)
      .setOrigin (0)
      .setDepth (9)
      .setScale (1);
    this.comboImage.x = this.top_bar_combo.x;
    this.comboImage.y = this.top_bar_combo.y + 2.5 * correction;

    // /* ADD SCORE PANEL !!!! NOT USED*/
    // this.add_score_panel = this.add
    //   .image (0, 0, `game_${device}_add_score_panel`)
    //   .setOrigin (0)
    //   .setDepth (3)
    //   .setVisible (false);
    // this.add_score_panel.x =
    //   this.top_game_gauge.width - this.add_score_panel.width + 23 * correction;
    // this.add_score_panel.y = this.top_bar_combo.y + 20 * correction;

    // this.addScoreObject = this.scoreModule.loadAddScoreObject (device);
    // this.addScoreObject.x = this.add_score_panel.x + 30 * correction;
    // this.addScoreObject.setVisible (false);

    /* FEVER GAUGE */
    for (let i = 1; i <= 7; i++) {
      this[`combo_gauge_${i}`] = this.add
        .image (0, 0, `game_${device}_combo_gauge`)
        .setOrigin (0)
        .setDepth (7)
        .setVisible (false);
      this[`combo_gauge_${i}`].x =
        this[`combo_gauge_${i}`].width * i + 15 + (i - 1) * 2 * correction;
      this[`combo_gauge_${i}`].y = this.top_bar_combo.y + 10 * correction;
    }
  }

  load_game_under_bar (device) {
    const correction = device === 'mob' ? 1 : 2;
    /* FRAME */
    this.under_bar_frame = this.add
      .image (0, 0, `game_${device}_under_bar_frame`)
      .setOrigin (0)
      .setDepth (6);
    this.under_bar_frame.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.under_bar_frame
    );
    this.under_bar_frame.y = this.positionCalc.yFractionalPosition (
      this.game,
      167,
      200
    );

    /* PAUSE BUTTON */
    this.pause_button = this.add
      .image (0, 0, `game_${device}_pause_button`)
      .setOrigin (0)
      .setDepth (7)
      .setInteractive ();
    this.pause_button.x = this.game.config.width - 125 * correction;
    this.pause_button.y = this.under_bar_frame.y + 25 * correction;

    this.popup_button = this.add
      .image (0, 0, `game_${device}_popup_button`)
      .setOrigin (0)
      .setDepth (7)
      .setInteractive ();
    this.popup_button.x = 75 * correction;
    this.popup_button.y = this.pause_button.y - 12 * correction;

    this.scoreObject = this.add
      .text (0, 0, this.scorePad, {
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff',
      })
      .setOrigin (0)
      .setFontSize (73 * correction)
      .setDepth (7)
      .setVisible (false);
    this.scoreObject.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.scoreObject
    ) + 10.5 *correction;
    this.scoreObject.y = this.pause_button.y - 20 * correction;

    this.scoreImage = this.add
      .image (0, 0, `game_${device}_zero_score`)
      .setOrigin (0)
      .setDepth (7)
      .setScale (1);
    this.scoreImage.x = this.scoreObject.x ;
    this.scoreImage.y = this.scoreObject.y + 2.5 * correction;
  }

  apply_pause_button_function (device) {
    const correction = device === 'mob' ? 2 : 1;
    var gap = device === 'mob' ? 60 : 0;

    this.pause_bg = this.add
      .image (0, 0, `game_${device}_pause_bg`)
      .setOrigin (0)
      .setDepth (13)
      .setVisible (false);

    this.pause_frame = this.add
      .image (0, 0, `game_${device}_pause_frame`)
      .setOrigin (0)
      .setDepth (13)
      .setVisible (false);
    this.pause_frame.x =
      this.positionCalc.xGameCenterCalc (this.game, this.pause_frame) +
      25 * correction;
    this.pause_frame.y =
      this.under_bar_frame.y - this.pause_frame.height + 15 * correction + gap;

    if (device === 'web') {
      this.pause_light_button = this.add
        .image (0, 0, 'game_web_pause_light_button')
        .setOrigin (0)
        .setDepth (13)
        .setVisible (false)
        .setInteractive ();
      this.pause_light_button.x =
        this.under_bar_frame.width - this.pause_light_button.width + 91;
      this.pause_light_button.y = this.under_bar_frame.y + 10;
    }

    this.pause_suspension_button = this.add
      .image (0, 0, `game_${device}_pause_suspension_button`)
      .setOrigin (0)
      .setDepth (13)
      .setVisible (false)
      .setInteractive ();
    var gap = device === 'mob' ? 10 : 24;
    this.pause_suspension_button.x = this.pause_frame.x + gap;
    var gap = device === 'mob' ? 10 : 18;
    this.pause_suspension_button.y = this.pause_frame.y + gap;

    this.pause_back_button = this.add
      .image (0, 0, `game_${device}_pause_back_button`)
      .setOrigin (0)
      .setDepth (13)
      .setVisible (false)
      .setInteractive ();
    var gap = device === 'mob' ? 10 : 24;
    this.pause_back_button.x = this.pause_frame.x + gap;
    var gap = device === 'mob' ? 195 : 390;
    this.pause_back_button.y = this.pause_suspension_button.y + gap;

    this.pause_button.on ('pointerdown', () => {
      this.bgm.pause ();
      this.pauseTime = this.bgm.seek;
      this.nodeManager.updateIsPauseTrue ();
      this.countdown.updateIsPauseTrue ();
      this.isPause = true;
      this.on_popup = true;
      this.keyboardEvent.toggleGameKey (device);
      if (device === 'mob') {
        this.touchEvents.toggleTouchPad ();
      }
      this.popup_button.disableInteractive ();
      this.pause_bg.setVisible (true);
      this.pause_frame.setVisible (true);
      if (device === 'web') {
        this.pause_light_button.setVisible (true);
      }
      this.pause_suspension_button.setVisible (true);
      this.pause_back_button.setVisible (true);
    });

    this.pause_suspension_button.on ('pointerdown', () => {
      if (this.pauseTime > 0) {
        this.bgm.play ({seek: this.pauseTime});
      }
      this.nodeManager.updateIsPauseFalse ();
      this.countdown.updateIsPauseFalse ();
      this.isPause = false;
      this.on_popup = false;
      this.keyboardEvent.toggleGameKey (device);
      if (device === 'mob') {
        this.touchEvents.toggleTouchPad ();
      }
      this.popup_button.setInteractive ();
      this.pause_bg.setVisible (false);
      this.pause_frame.setVisible (false);
      if (device === 'web') {
        this.pause_light_button.setVisible (false);
      }
      this.pause_suspension_button.setVisible (false);
      this.pause_back_button.setVisible (false);
    });

    this.pause_back_button.on ('pointerdown', () => {
      this.pause_bg.setVisible (false);
      this.pause_frame.setVisible (false);
      if (device === 'web') {
        this.pause_light_button.setVisible (false);
      }
      this.popup_button.setInteractive ();
      this.pause_suspension_button.setVisible (false);
      this.pause_back_button.setVisible (false);
      this.on_popup = false;
      this.keyboardEvent.toggleGameKey (device);
      if (device === 'mob') {
        this.touchEvents.toggleTouchPad ();
      }

      this.scene.stop ('game');
      this.scene.start ('main');
      this.resetGameState ();
    });
  }

  apply_popup_button_function (device) {
    const correction = device === 'mob' ? 1 : 2;
    this.popup_bg = this.add
      .image (0, 0, `game_${device}_popup_bg`)
      .setOrigin (0)
      .setDepth (10)
      .setVisible (false);

    this.popup_content = this.add
      .image (0, 0, `game_${device}_popup_content`)
      .setOrigin (0)
      .setDepth (11)
      .setVisible (false);
    this.popup_content.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.popup_content
    );
    this.popup_content.y =
      this.under_bar_frame.y - this.popup_content.height + 85 * correction;

    this.popup_close_button = this.add
      .image (0, 0, `game_${device}_popup_close_button`)
      .setOrigin (0)
      .setDepth (11)
      .setVisible (false)
      .setInteractive ();
    this.popup_close_button.x = this.popup_content.x + 22.5 * correction;
    this.popup_close_button.y = this.under_bar_frame.y + 15 * correction;

    this.popup_button.on ('pointerdown', () => {
      this.bgm.pause ();
      this.pauseTime = this.bgm.seek;
      this.nodeManager.updateIsPauseTrue ();
      this.countdown.updateIsPauseTrue ();
      this.isPause = true;
      this.on_popup = true;
      this.keyboardEvent.toggleGameKey (device);
      if (device === 'mob') {
        this.touchEvents.toggleTouchPad ();
      }
      this.pause_button.disableInteractive ();
      this.popup_bg.setVisible (true);
      this.popup_content.setVisible (true);
      this.popup_close_button.setVisible (true);
    });

    this.popup_close_button.on ('pointerdown', () => {
      if (this.pauseTime > 0) {
        this.bgm.play ({seek: this.pauseTime});
      }
      this.nodeManager.updateIsPauseFalse ();
      this.countdown.updateIsPauseFalse ();
      this.isPause = false;
      this.on_popup = false;
      this.keyboardEvent.toggleGameKey (device);
      if (device === 'mob') {
        this.touchEvents.toggleTouchPad ();
      }
      this.pause_button.setInteractive ();
      this.popup_bg.setVisible (false);
      this.popup_content.setVisible (false);
      this.popup_close_button.setVisible (false);
    });
  }

  load_key_press_effect (device) {
    const correction = device === 'mob' ? 1 : 2;
    const gameKeys = ['d', 'f', 'j', 'k'];

    gameKeys.forEach (gameKey => {
      /* ROUTE EFFECT */
      this.anims.create ({
        key: 'game_route_effect',
        frames: this.anims.generateFrameNames (`game_${device}_route_motion`, {
          prefix: 'sprite',
          start: 1,
          end: 4,
          zeroPad: 1,
        }),
        frameRate: 20,
        repeat: 0,
      });

      /* MIDDLE STAR EFFECT */
      this.anims.create ({
        key: 'game_middle_star_effect',
        frames: this.anims.generateFrameNames (
          `game_${device}_middle_star_motion`,
          {
            prefix: 'sprite',
            start: 1,
            end: 4,
            zeroPad: 1,
          }
        ),
        frameRate: 20,
        repeat: 0,
      });

      /* SMALL STAR EFFECT */
      this.anims.create ({
        key: 'game_small_star_effect',
        frames: this.anims.generateFrameNames (
          `game_${device}_small_star_motion`,
          {
            prefix: 'sprite',
            start: 1,
            end: 4,
            zeroPad: 1,
          }
        ),
        frameRate: 20,
        repeat: 0,
      });
    });
  }

  game_start (device) {
    this.feverEffect = this.feverModule.loadFeverEffectImage (device);

    this.keyboardEvent.loadGameKey (device);
    if (device === 'mob') {
      this.touchEvents.loadTouchScreen ();
    }

    /* Countdown before the game start */
    this.countdown = new Countdown (this);
    this.countdown.setCountdown (this.countdownNumber, device); //dev. down music sound

    /* Play BGM */
    this.bgm = this.sound.add ('bgm', {loop: false});

    /* Remove nodefile cache */
    let nodeFileKey = 'nodeFile';
    if (this.cache.text.has (nodeFileKey)) {
      this.nodeFile = this.cache.text.get (nodeFileKey);
      this.cache.text.remove (nodeFileKey);
    }

    /* Load the nodes */
    const node_manager_param_ismobile = device === 'mob' ? true : false;
    this.nodeManager = new NodeManager (
      this,
      this.nodeFile,
      this.isPause,
      node_manager_param_ismobile
    );
    this.nodes = this.nodeManager.makeRectFromClass ();

    /** Switch to the 'result' scene 3 seconds after the music ends. */
    this.bgm.on ('complete', () => {
      this.time.addEvent ({
        delay: 3000,
        callback: () => {
          this.scene.stop ('game');
          this.scene.start ('result', {
            score: this.score,
            musicInfo: this.musicInfo,
          });
          this.resetGameState ();
          if (this.cache.audio.exists ('bgm')) {
            this.cache.audio.remove ('bgm');
          }
        },
        callbackScope: this,
      });
    });
  }

  set_up_layout_frame () {
    ['D', 'F', 'J', 'K'].forEach (key => {
      this['routeOfKey' + key] = this.gameUI.createRoute ('key' + key);
    });
  }

  set_up_next_page () {
    const result_button = this.add
      .rectangle (
        this.game.config.width * 4 / 5,
        this.game.config.height * 1 / 30,
        200,
        100,
        {fill: '#ffffff'}
      )
      .setOrigin (0)
      .setInteractive ();
    result_button.on ('pointerdown', () => {
      this.bgm.stop ();
      this.scene.stop ('game');
      this.scene.start ('result', {
        score: 104455,
        musicInfo: this.musicInfo,
      });
      if (this.cache.audio.exists ('bgm')) {
        this.cache.audio.remove ('bgm');
      }
      this.resetGameState ();
    });
  }

  load_pause_key_esc () {
    this.input.keyboard.on ('keydown-ESC', () => {
      const pointer = this.input.activePointer;
      this.pause_button.emit ('pointerdown', pointer);
      this.pause_button.emit ('pointerup', pointer);
    });
  }

  load_judgement_motion (device) {
    this.anims.create ({
      key: 'perfect',
      frames: this.anims.generateFrameNames (`game_${device}_perfect_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 10,
        zeroPad: 1,
      }),
      frameRate: 28,
      repeat: 0,
    });

    this.anims.create ({
      key: 'great',
      frames: this.anims.generateFrameNames (`game_${device}_great_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 10,
        zeroPad: 1,
      }),
      frameRate: 28,
      repeat: 0,
    });

    this.anims.create ({
      key: 'good',
      frames: this.anims.generateFrameNames (`game_${device}_good_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 10,
        zeroPad: 1,
      }),
      frameRate: 28,
      repeat: 0,
    });

    this.anims.create ({
      key: 'miss',
      frames: this.anims.generateFrameNames (`game_${device}_miss_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 10,
        zeroPad: 1,
      }),
      frameRate: 28,
      repeat: 0,
    });

    this.anims.create ({
      key: 'early',
      frames: this.anims.generateFrameNames (`game_${device}_early_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 10,
        zeroPad: 1,
      }),
      frameRate: 28,
      repeat: 0,
    });
  }

  createProgressBar() {
    let progressBox = this.add.graphics();
    let progressBar = this.add.graphics();

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    progressBox.fillStyle(0x222222, 1);
    progressBox.fillRect((width / 4), (height / 2 + 50), (width / 2), 100);

    const loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: '도피중...',
      style: {
        fontSize: '100px',
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff',
      }
    });
    loadingText.setOrigin(0.5, 0.5);

    /*let percentText = this.make.text({
      x: width / 2,
      y: height / 2 + 100,
      text: '0%',
      style: {
        fontSize: '100px',
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff'
      }
    });
    percentText.setOrigin(0.5, 0.5);*/


	let firstOverValue = 0;
    let prevX = 0;
    this.load.on('progress', (value) => {
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      //progressBar.fillRect(250, 280, 300 * value, 30);

      let progressX = (width / 2) * value;
      if(value > 0.5){
        firstOverValue = (firstOverValue === 0) ? value : firstOverValue;
        //마지막의 경우 좀 더 진행률 이해를 위해 적게 표시 > value - 50
        let tobeX = (width / 2 - 20 ) * firstOverValue - 50
        if(tobeX > progressX){
          // = (width / 2) * firstOverValue;
          progressX = tobeX;
        }
        //console.log("progressX : " + progressX);
      }
      if(value > 0.9 && value <= 1){
        progressX = prevX
      }
      prevX = progressX;
      progressBar.fillRect((width / 4), (height / 2 + 50), progressX, 100);
      //percentText.setText(parseInt(value * 100) + '%');
    });

    /*this.load.on('fileprogress', (file) => {
      console.log('Loading asset: ' + file.key)
    });*/

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      //percentText.destroy();
    })
  }
}
