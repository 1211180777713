export default class Fever {
  constructor (scene) {
    this.scene = scene;
  }


  loadFeverEffectImage (device) {
    const correction  = device === 'mob'? 2 : 1;
    this.scene.anims.create ({
      key: 'game_fever_effect',
      frames: this.scene.anims.generateFrameNames (
        `game_${device}_fever_motion`,
        {
          prefix: 'sprite',
          start: 1,
          end: 30,
          zeroPad: 1,
        }
      ),
      frameRate: 20,
      repeat: -1,
    });
    const fever_image = this.scene.add
      .sprite (0, 0, `game_${device}_fever_motion`)
      .setDepth (10)
      .setOrigin (0)
      .setScale (2 * correction)
      .setVisible (false);

    fever_image.x = this.scene.game.config.width / 2;
    fever_image.y = this.scene.game.config.height / 2;
    return fever_image;
  }

  setFever () {
    if (this.scene.isFever) {
      this.scene.feverEffect.setVisible(true).play('game_fever_effect');
      this.scene.top_fever_gauge.setVisible(true);
      this.scene.top_bar_combo.setStyle({ fill: '#ff9cff' });
    } else {
      this.scene.feverEffect.setVisible(false).stop();
      this.scene.top_fever_gauge.setVisible(false);
      this.scene.top_bar_combo.setStyle({ fill: '#ffffff' });
    }
  }
}
