import Phaser from 'phaser';

/* WEB VIEW ASSETS */
import result_web_bg from '../assets/img/result/web_view/background.png';
import result_web_curcuit from '../assets/img/result/web_view/curcuit.png';
import result_web_description
  from '../assets/img/result/web_view/description.png';
import result_web_home_button
  from '../assets/img/result/web_view/home_button.png';
import result_web_title from '../assets/img/result/web_view/title.png';
import result_web_score_bar from '../assets/img/result/web_view/score_bar.png';
import result_web_ranking_bg
  from '../assets/img/result/web_view/ranking_bg.png';
import result_web_title_1
  from '../assets/img/result/web_view/music_param/result_web_title_1.png';
import result_web_title_2
  from '../assets/img/result/web_view/music_param/result_web_title_2.png';
import result_web_title_3
  from '../assets/img/result/web_view/music_param/result_web_title_3.png';
import result_web_title_4
  from '../assets/img/result/web_view/music_param/result_web_title_4.png';
import result_web_title_5
  from '../assets/img/result/web_view/music_param/result_web_title_5.png';
  import welcome_web_circuit_motion
  from '../assets/animation/welcome/welcome_web_circuit_motion.png';

/* MOBILE VIEW ASSETS */
import result_mob_bg from '../assets/img/result/mobile_view/background.png';
import result_mob_circuit from '../assets/img/result/mobile_view/circuit.png';
import result_mob_circuit_cover
  from '../assets/img/result/mobile_view/circuit_cover.png';
import result_mob_description
  from '../assets/img/result/mobile_view/description.png';
import result_mob_distributor
  from '../assets/img/result/mobile_view/distributor.png';
import result_mob_home_button
  from '../assets/img/result/mobile_view/home_button.png';
import result_mob_ranking_bg
  from '../assets/img/result/mobile_view/ranking_bg.png';
import result_mob_score_bar
  from '../assets/img/result/mobile_view/score_bar.png';
import result_mob_score_bg from '../assets/img/result/mobile_view/score_bg.png';
import result_mob_title from '../assets/img/result/mobile_view/title.png';
import result_mob_home_button_bg
  from '../assets/img/result/mobile_view/home_button_bg.png';
import result_mob_title_1
  from '../assets/img/result/mobile_view/music_param/result_mob_title_1.png';
import result_mob_title_2
  from '../assets/img/result/mobile_view/music_param/result_mob_title_2.png';
import result_mob_title_3
  from '../assets/img/result/mobile_view/music_param/result_mob_title_3.png';
import result_mob_title_4
  from '../assets/img/result/mobile_view/music_param/result_mob_title_4.png';
import result_mob_title_5
  from '../assets/img/result/mobile_view/music_param/result_mob_title_5.png';
  import welcome_mob_circuit_motion
  from '../assets/animation/welcome/welcome_mob_circuit_motion.png';

/* PUBLIC ASSETS */
import score_0 from '../assets/img/result/score/0.png';
import score_1 from '../assets/img/result/score/1.png';
import score_2 from '../assets/img/result/score/2.png';
import score_3 from '../assets/img/result/score/3.png';
import score_4 from '../assets/img/result/score/4.png';
import score_5 from '../assets/img/result/score/5.png';
import score_6 from '../assets/img/result/score/6.png';
import score_7 from '../assets/img/result/score/7.png';
import score_8 from '../assets/img/result/score/8.png';
import score_9 from '../assets/img/result/score/9.png';
import result_ranking_s from '../assets/img/result/ranking/ranking_s.png';
import result_ranking_a from '../assets/img/result/ranking/ranking_a.png';
import result_ranking_b from '../assets/img/result/ranking/ranking_b.png';
import result_ranking_c from '../assets/img/result/ranking/ranking_c.png';

/* Module */
import Coordinate from '../theme/Coordinate';
import ScoreToRank from '../converter/ScoreToRank';
import ScoreToImage from '../converter/ScoreToImage';
import ValidateDevice from '../theme/ValidateDevice';
import PositionCalc from '../theme/PositionCalc';

// /* dev. temp assets */
import Music from '../music_info/Music';
// import game1_music from '../assets/music/Finding_home.mp3';
import game4_node from '../assets/textNode/Finding_home.txt';

export default class Result extends Phaser.Scene {
  constructor () {
    super ('result');
  }

  init (data) {
    /* Receive data from game scene */
    this.musicInfo = data.musicInfo;
    this.score = data.score;

    // /* dev. temp assets */
    // this.musicInfo = new Music (
    //   4,
    //   'Look At That',
    //   'Holland|Hypnosis Therapy',
    //   'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Look_at_that.mp3',
    //   game4_node
    // )
    // this.score = 49536;
  }

  preload () {
    /* LOAD MODULES */
    this.coordinate = new Coordinate ();
    this.validateDevice = new ValidateDevice (this);
    this.positionCalc = new PositionCalc ();
    this.scoreToImage = new ScoreToImage (this);
    this.scoreToRank = new ScoreToRank (this.musicInfo.id, this.score);

    /* CONVERT SOCRE TO RANK IMAGE PATH */
    this.currnet_rank = this.scoreToRank.getRank ();
    this.rank_image_path = `result_ranking_${this.currnet_rank}`;

    /* VALIDATE CONNECTED DEVICE */
    const isMobile = this.validateDevice.isMobile ();

    /* LOAD PUBLIC ASSETS */
    this.load.image ('score_0', score_0);
    this.load.image ('score_1', score_1);
    this.load.image ('score_2', score_2);
    this.load.image ('score_3', score_3);
    this.load.image ('score_4', score_4);
    this.load.image ('score_5', score_5);
    this.load.image ('score_6', score_6);
    this.load.image ('score_7', score_7);
    this.load.image ('score_8', score_8);
    this.load.image ('score_9', score_9);
    this.load.image ('result_ranking_s', result_ranking_s);
    this.load.image ('result_ranking_a', result_ranking_a);
    this.load.image ('result_ranking_b', result_ranking_b);
    this.load.image ('result_ranking_c', result_ranking_c);

    if (isMobile) {
      /* LOAD MOBILE VIEW ASSETS*/
      this.load.image ('result_mob_bg', result_mob_bg);
      this.load.image ('result_mob_circuit', result_mob_circuit);
      this.load.image ('result_mob_circuit_cover', result_mob_circuit_cover);
      this.load.image ('result_mob_description', result_mob_description);
      this.load.image ('result_mob_distributor', result_mob_distributor);
      this.load.image ('result_mob_home_button', result_mob_home_button);
      this.load.image ('result_mob_ranking_bg', result_mob_ranking_bg);
      this.load.image ('result_mob_score_bar', result_mob_score_bar);
      this.load.image ('result_mob_score_bg', result_mob_score_bg);
      this.load.image ('result_mob_title', result_mob_title);
      this.load.image ('result_mob_home_button_bg', result_mob_home_button_bg);
      this.load.image ('result_mob_title_1', result_mob_title_1);
      this.load.image ('result_mob_title_2', result_mob_title_2);
      this.load.image ('result_mob_title_3', result_mob_title_3);
      this.load.image ('result_mob_title_4', result_mob_title_4);
      this.load.image ('result_mob_title_5', result_mob_title_5);
      this.load.atlas (
        'welcome_mob_circuit_motion',
        welcome_mob_circuit_motion,
        '../assets/animation/welcome/welcome_mob_circuit_motion.json'
      );
    } else {
      /* LOAD WEB VIEW ASSETS*/
      this.load.image ('result_web_bg', result_web_bg);
      this.load.image ('result_web_curcuit', result_web_curcuit);
      this.load.image ('result_web_description', result_web_description);
      this.load.image ('result_web_home_button', result_web_home_button);
      this.load.image ('result_web_title', result_web_title);
      this.load.image ('result_web_ranking_bg', result_web_ranking_bg);
      this.load.image ('result_web_score_bar', result_web_score_bar);
      this.load.image ('result_web_title_1', result_web_title_1);
      this.load.image ('result_web_title_2', result_web_title_2);
      this.load.image ('result_web_title_3', result_web_title_3);
      this.load.image ('result_web_title_4', result_web_title_4);
      this.load.image ('result_web_title_5', result_web_title_5);
      this.load.atlas (
        'welcome_web_circuit_motion',
        welcome_web_circuit_motion,
        '../assets/animation/welcome/welcome_web_circuit_motion.json'
      );
    }
  }

  create () {
    /* INPUT SCORE TO DATABASE */
    this.fetchDate ();

    /* VALIDATE CONNECTED DEVICE */
    const isMobile = this.validateDevice.isMobile ();
    const device = isMobile ? 'mob' : 'web';

    /* LOAD SCORE UI AND UNITS */
    this.load_score_bar (device);
    this.load_score_object (device);

    /* LOAD BACKGROUND */
    this.load_background_iamge (device);

    this.load_ranking_box (device);
    this.load_ranking_object (this.rank_image_path, device);

    this.load_home_button (device);
  }

  update () {}

  /* Insert game score  */
  async fetchDate () {
    const postData = {
      music_no: this.musicInfo.id,
      score: this.score,
    };

    try {
      const response = await fetch (
        'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/insertScore',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify (postData),
        }
      );
      if (!response.ok) {
        throw new Error ('Network response was not ok');
      }
      const responseData = await response.json ();
    } catch (error) {
      console.error (error);
    }
  }

  load_background_iamge (device) {
    const bg_x_position = device === 'mob' ? 0 : -10;
    const correction = device === 'mob' ? 1 : 2;
    /* BACKGROUND */
    this.add
      .image (bg_x_position, 0, `result_${device}_bg`)
      .setOrigin (0)
      .setDepth (0);



    const spirte_count = device === 'mob'? 18 : 36;
    /* CIRCUIT MOTION */
    this.anims.create ({
      key: 'welcome_circuit_motion',
      frames: this.anims.generateFrameNames (`welcome_${device}_circuit_motion`, {
        prefix: 'sprite',
        start: 1,
        end: spirte_count,
        zeroPad: 1,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.circuit_motion = this.add
      .sprite (0, 0, `welcome_${device}_circuit_motion`)
      .play ('welcome_circuit_motion')
      .setDepth (0)
      .setOrigin(0)
      .setScale (4);

      this.circuit_motion.x = this.game.config.width / 2;
      this.circuit_motion.y = this.game.config.height / 2;

    /* TITLE */
    const musicIndex = this.musicInfo.id;
    this.title = this.add
      .image (0, 0, `result_${device}_title_${musicIndex}`)
      .setOrigin (0)
      .setDepth (0);
    this.title.x = this.positionCalc.xGameCenterCalc (this.game, this.title);
    var gap = device === 'mob' ? -30 : 0
    this.title.y = this.score_bar.y + this.title.height + gap;

    /* DESCRIPTION (YOUR SCORE) */
    this.description = this.add
      .image (0, 0, `result_${device}_description`)
      .setOrigin (0)
      .setDepth (0);
    this.description.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.description
    );
    var gap = device === 'mob'? -10 : 0;
    this.description.y =
      this.title.y - this.description.height - 175 * correction + gap;
  }

  load_score_bar (device) {
    const correction = device === 'mob' ? 1 : 2;
    this.score_bar = this.add
      .image (0, 0, `result_${device}_score_bar`)
      .setOrigin (0)
      .setDepth (2);
    this.score_bar.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.score_bar
    );
    this.score_bar.y =
      this.positionCalc.yGameCenterCalc (this.game, this.score_bar) + 75 * correction;

    if (device === 'mob') {
      this.score_bar_bg = this.add
        .image (0, 0, `result_${device}_score_bg`)
        .setOrigin (0)
        .setDepth (1);
      this.score_bar_bg.x = this.score_bar.x - 36;
      this.score_bar_bg.y = this.score_bar.y - 36;
    }
  }

  load_score_object (device) {
    const scale = device === 'mob' ? 0.5 : 1;
    const correction = device === 'mob' ? 0.5 : 2.5;
    const gap = device === 'mob'? 70 : 0;
    const images = this.scoreToImage.convertImage (this.score, device);
    images.forEach (image => {
      image.setScale (scale);
      image.y =
        this.positionCalc.yGameCenterCalc (this.game, image) + 95 * correction + gap;
    });
  }

  load_home_button (device) {
    const correction = device === 'mob' ? 1 : 1.5;
    this.home_button = this.add
      .image (0, 0, `result_${device}_home_button`)
      .setOrigin (0)
      .setInteractive ()
      .setDepth (1);
    this.home_button.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.home_button
    );
    this.home_button.y = this.title.y + 240 * correction;
    this.home_button.on ('pointerdown', () => {
      this.scene.stop ('result');
      this.scene.start ('welcome');
      this.musicInfo = null;
      this.score = 0;
    });

    if (device === 'mob') {
      this.home_button_bg = this.add
        .image (0, 0, `result_${device}_home_button_bg`)
        .setOrigin (0)
        .setInteractive ()
        .setDepth (0);
      this.home_button_bg.x = this.home_button.x - 36;
      this.home_button_bg.y = this.home_button.y - 36;
    }
  }

  load_ranking_box (device) {
    this.ranking_bg = this.add
      .image (0, 0, `result_${device}_ranking_bg`)
      .setOrigin (0)
      .setDepth (0);
    this.ranking_bg.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.ranking_bg
    );
    this.ranking_bg.y = this.description.y - this.ranking_bg.height;
  }

  load_ranking_object (ranking_image_path, device) {
    const scale = device === 'mob' ? 0.5 : 1;
    const correction = device === 'mob' ? 60 : 340;
    this.ranking = this.add
      .image (0, 0, ranking_image_path)
      .setOrigin (0)
      .setScale (scale)
      .setDepth (1);
    this.ranking.x = this.positionCalc.xGameCenterCalc (
      this.game,
      this.ranking
    );
    this.ranking.y = this.description.y - this.ranking.height / 2 - correction;
  }
}
