import React, {useEffect} from 'react';
import Phaser from 'phaser';
import Welcome from './scenes/Welcome';
import Game from './scenes/Game';
import Main from './scenes/Main';
import Result from './scenes/Result';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

function App () {
  const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test (
    navigator.userAgent
  )
    ? 'Mobile'
    : 'Desktop';
  const game_width = deviceType === 'Mobile' ? 1125 : 2251;
  const game_height = deviceType === 'Mobile' ? 2436 : 4873;
  const antialiasValue = deviceType === 'Mobile' ? false : true;

  const musicUrls = {
    game1_music: 'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Finding_home.mp3',
    game2_music: 'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/NMMG.mp3',
    game3_music: 'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Dome_before_30mins.mp3',
    game4_music: 'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Look_at_that.mp3',
    game5_music: 'https://port-0-node-express-1272llwr928mp.sel5.cloudtype.app/api/music/Oasis.mp3',
  };

  const apiKey = 'anothersunday';

  useEffect (
    () => {
      const config = {
        type: Phaser.AUTO,
        width: game_width,
        height: game_height,
        physics: {
          default: 'arcade',
          debug: true,
        },
        input: {
          activePointers: 10,
        },
        scene: [Welcome, Main, Game, Result],
        plugins: {
          scene: [
            {
              key: 'rexUI',
              plugin: UIPlugin,
              mapping: 'rexUI',
            },
          ],
        },
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
        },
        fps: {
          target: 60,
          forceSetTimeOut: true,
        },
        render: {
          pixelArt: false,
          antialias: false,
          antialiasGL: antialiasValue,
        },
      };

      const game = new Phaser.Game (config);
      window.game = game;

      game.scene.start ('welcome', {musicUrls, apiKey});

      return () => {
        game.destroy (true);
      };
    },
    [game_width, game_height]
  );

  return <div style={{width: '100%', height: '100%'}} id="game-container" />;
}

export default App;
