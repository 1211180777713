export default class Score {
  constructor(scene) {
    this.scene = scene;
  }

  updateScore() {
    const scorePad = this.scene.score.toString().padStart(10, '0');
    
    this.scene.scoreObject.setText(scorePad);
  }


  updateTotalScore(score) {
    if (this.scene.isFever) {
      this.scene.score += score * 1.2;
    }
    this.scene.score += score;
  }

  // loadScoreObject(game) {
  //    const scoreObject = this.scene.add
  //     .text(0, 0, '0000000000', {
  //       fontFamily: 'SchwarzHalbschmal',
  //       color: '#ffffff',
  //     })
  //     .setOrigin(0)
  //     .setFontSize(200)
  //     .setFontStyle("bold")
  //     .setDepth(4);
  //     scoreObject.x = this.scene.positionCalc.xGameCenterCalc(game, scoreObject);
  //     scoreObject.y = this.scene.pause_button.y - 50;
  //   return scoreObject;
  // }

  // loadAddScoreObject(device) {
  //   const correction = device === 'mob' ? 1 : 2;
  //   return this.scene.add
  //     .text(0, 0, this.addScore, {
  //       fontFamily: 'KMRWaldenburg',
  //       color: '#eafceb'
  //     })
  //     .setOrigin(0)
  //     .setFontSize(50 * correction)
  //     .setFontStyle("bold")
  //     .setDepth(5)
  //     .setAlpha(1);
  // }


  // updateAddScore() {
  //   this.scene.addScoreObject.setText(this.scene.addScore);
  // }

  // setAddScoreNull() {
  //   this.scene.addScore = null;
  // }

  // setAddScore(score) {
  //   if (this.scene.isFever) {
  //     score *= 1.2;
  //   }
  //   this.scene.addScore = "+" + score;
  // }

  
  // animateAddScore() {
  //   this.scene.addScoreObject.y -= 10;
  //   this.scene.addScoreObject.alpha -= 0.1;
  // }

  // resetPositionAddScore(){
  //   this.scene.addScoreObject.y = this.scene.top_bar_score.y + 120;
  //   this.scene.addScoreObject.alpha = 1;
  // }
}
