import Coordinate from "../theme/Coordinate";

export default class GameUI {
  constructor(scene) {
    this.scene = scene;

    this.coordinate = new Coordinate();
  }

  createRoute(key) {
    const route = this.scene.add
      .rectangle(
        this.coordinate.xPosit[key],
        this.coordinate.yPosit.nodeRoute,
        this.coordinate.width.nodeRoute,
        this.coordinate.height.nodeRoute,
        this.coordinate.color.nodeRoute
      )
      .setOrigin(0)
      .setAlpha(0.5);

    this.createKeyInfo(key);

    return route;
  }

  createKeyInfo(key) {
    this.scene.add.text(
      this.coordinate.xPosit[key] + 55,
      this.coordinate.yPosit.nodeRoute + 1000,
      key.substring(3),
      { fill: "0x000000" }
    );
  }

  loadKeyPadImage(key, isMobile = false) {
    const device = isMobile ? "mob" : "web";
    const keyMap = {
      d: 1,
      f: 2,
      j: 3,
      k: 4
    };
    const correction_x = device === "mob" ? 80 : 145;
    const correction_y = device === "mob" ? 15 : 110;
  
    const keyButton = this.scene.add
      .image(0, 0, `game_${device}_key_${key}_button`)
      .setOrigin(0.5)
      .setInteractive()
      .setDepth(6);
    keyButton.x = this.scene.positionCalc.xFractionalPosition(this.scene.game, keyMap[key], 4) - keyButton.width + correction_x;
    keyButton.y = this.scene.positionCalc.yFractionalPosition(this.scene.game, 3, 4) + keyButton.height - correction_y;
  
    return keyButton;
  }

  loadEffectKeyPadImage(key, isMobile = false) {
    const device = isMobile ? "mob" : "web";
    const keyMap = {
      d: 1,
      f: 2,
      j: 3,
      k: 4
    };
    const correction_x = device === "mob" ? 45 : 90;
    const correction_y = device === "mob" ? 15 : 60;
  
    const keyButton = this.scene.add
      .image(0, 0, `game_${device}_key_${key}_button_effect`)
      .setOrigin(0.5)
      .setDepth(6)
      .setVisible(false);
    keyButton.x = this.scene[`key_${key}_button`].x ;
    keyButton.y = this.scene.key_d_button.y;
  
    return keyButton;
  }
  

  loadTextSlide(xPosit){
    return this.scene.add
    .image(xPosit, 2280, "game_thumbnail")
    .setOrigin(0)
    .setDepth(5)
    .setScale(0.9);
  }

  setupJudgementLine(device) {
    const correction = device === 'mob'? 20 : 120;
    return (this.judgementLine = this.scene.add
      .rectangle(
        0,
        this.scene.game.config.height * 7 / 10 + correction,
        this.scene.game.config.width,
        8,
        this.coordinate.color.judgementLine
      )
      .setOrigin(0))
      .setDepth(7)
      .setAlpha(0.01); //dev.
  }

}
