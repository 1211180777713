export default class ScoreToImage{
    constructor(scene){
        this.scene = scene;
    }

    convertImage(score, device){
        const correction = device === "mob"? 0.5 : 1;
        const scoreStr = score.toString();
        const digits = scoreStr.split('');
        const images = [];
        const imageWidth = 137 * correction;
        const gap = 20 * correction;
        const startX = this.scene.coordinate.xPosit.center - (digits.length * imageWidth + digits.length * gap - 120 * correction) / 2;

        digits.forEach((digit, index) => {
            const image = this.scene.add.image(startX + (index *( imageWidth + gap )), 0, `score_${digit}`).setOrigin(0.5).setDepth(4);

            images.push(image);
        });

        return images;
    }
}