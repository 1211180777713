import Phaser from 'phaser';

/* CONNECT MODULES */
import ValidateDevice from '../theme/ValidateDevice';
import PositionCalc from '../theme/PositionCalc';

/* WEB VIEW ASSETS */
import welcome_web_bg from '../assets/img/welcome/web_view/background.png';
import welcome_web_distributor
  from '../assets/img/welcome/web_view/distributor.png';
import welcome_web_title_motion
  from '../assets/animation/welcome/welcome_web_title_motion.png';
import welcome_web_circuit_motion
  from '../assets/animation/welcome/welcome_web_circuit_motion.png';

/* MOBILE VIEW ASSETS */
import welcome_mob_bg from '../assets/img/welcome/mobile_view/background.png';
import welcome_mob_distributor
  from '../assets/img/welcome/mobile_view/distributor.png';
import welcome_mob_title_motion
  from '../assets/animation/welcome/welcome_mob_title_motion.png';
import welcome_mob_circuit_motion
  from '../assets/animation/welcome/welcome_mob_circuit_motion.png';
  

export default class Welcome extends Phaser.Scene {
  constructor () {
    super ('welcome');
    this.sprite = null;
  }

  preload () {
    // 진행률
    this.createProgressBar();

    /* CONNECT MODULE */
    this.positionCalc = new PositionCalc ();
    this.validateDevice = new ValidateDevice (this);
    this.isMobile = this.validateDevice.isMobile ();

    if (this.isMobile) {
      /* LOAD MOBILE ASSETS */
      this.load.image ('welcome_mob_bg', welcome_mob_bg);
      this.load.image ('welcome_mob_distributor', welcome_mob_distributor);
      this.load.atlas (
        'welcome_mob_title_motion',
        welcome_mob_title_motion,
        '../assets/animation/welcome/welcome_mob_title_motion.json'
      );
      this.load.atlas (
        'welcome_mob_circuit_motion',
        welcome_mob_circuit_motion,
        '../assets/animation/welcome/welcome_mob_circuit_motion.json'
      );
    } else {
      /* LOAD WEB ASSETS */
      this.load.image ('welcome_web_bg', welcome_web_bg);
      this.load.image ('welcome_web_distributor', welcome_web_distributor);
      this.load.atlas (
        'welcome_web_title_motion',
        welcome_web_title_motion,
        '../assets/animation/welcome/welcome_web_title_motion.json'
      );
      this.load.atlas (
        'welcome_web_circuit_motion',
        welcome_web_circuit_motion,
        '../assets/animation/welcome/welcome_web_circuit_motion.json'
      );
    }
  }

  create (){

    /* VALIDATE ENTERD DEVICE */
    const device = this.isMobile ? 'mob' : 'web';

    /* LOAD UI */
    this.load_background_image (device);
    this.load_start_motion (device);

    if (device === 'web') {
      this.load_enter_game_key ();
    }
  }

  load_background_image (device) {
    this.add.image (0, 0, `welcome_${device}_bg`).setOrigin (0);

    const distributor = this.add
      .image (0, 0, `welcome_${device}_distributor`)
      .setOrigin (0);
    distributor.x = this.positionCalc.xGameCenterCalc (this.game, distributor);
    distributor.y = this.positionCalc.yGameCenterCalc (this.game, distributor);
  }

  load_start_motion (device) {
    var correction = device === 'mob' ? 15 : 30;

    /* ENTER BUTTON MOTION */
    this.anims.create ({
      key: 'effect',
      frames: this.anims.generateFrameNames (`welcome_${device}_title_motion`, {
        prefix: 'sprite',
        start: 1,
        end: 42,
        zeroPad: 1,
      }),
      frameRate: 20,
      repeat: 0,
    });

    this.sprite = this.add
      .sprite (0, 0, `welcome_${device}_title_motion`)
      .play ('effect')
      .setDepth (3)
      .setScale (2);
    this.sprite.x = this.game.config.width / 2 - this.sprite.width;
    this.sprite.y =
      this.positionCalc.yGameCenterCalc (this.game, this.sprite) - correction;

    this.sprite.on ('animationupdate', (animation, frame) => {
      if (frame.index === 38) {
        this.sprite.anims.stop ();
        this.sprite.setInteractive ();
        this.sprite.on ('pointerdown', () => {
          this.scene.stop ('welcome');
          this.scene.start ('main');
        });
      }
    });

    
    const frame_rate_of_device = device === 'mob'? 10 : 20
    const spirte_count = device === 'mob'? 18 : 36;
    /* CIRCUIT MOTION */
    this.anims.create ({
      key: 'welcome_circuit_motion',
      frames: this.anims.generateFrameNames (`welcome_${device}_circuit_motion`, {
        prefix: 'sprite',
        start: 1,
        end: spirte_count,
        zeroPad: 1,
      }),
      frameRate: frame_rate_of_device,
      repeat: -1,
    });

    this.circuit_motion = this.add
      .sprite (0, 0, `welcome_${device}_circuit_motion`)
      .play ('welcome_circuit_motion')
      .setDepth (2)
      .setOrigin(0)
      .setScale (2);

      this.circuit_motion.x = this.game.config.width / 2;
      this.circuit_motion.y = this.game.config.height / 2;
  }

  load_enter_game_key () {
    this.input.keyboard.on ('keydown-ENTER', () => {
      const pointer = this.input.activePointer;
      this.sprite.emit ('pointerdown', pointer);
    });
  }



  createProgressBar() {
    // 진행 바를 생성합니다
    let progressBox = this.add.graphics();
    let progressBar = this.add.graphics();

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    progressBox.fillStyle(0x222222, 1);
    progressBox.fillRect((width / 4), (height / 2 + 50), (width / 2), 100);

    const loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: 'Loading...',
      style: {
        fontSize: '100px',
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff',
      }
    });
    loadingText.setOrigin(0.5, 0.5);

    /*let percentText = this.make.text({
      x: width / 2,
      y: height / 2 + 100,
      text: '0%',
      style: {
        fontSize: '100px',
        fontFamily: 'SchwerHalbschmal',
        color: '#ffffff'
      }
    });
    percentText.setOrigin(0.5, 0.5);*/


	let firstOverValue = 0;
    let prevX = 0;
    this.load.on('progress', (value) => {
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      //progressBar.fillRect(250, 280, 300 * value, 30);

      let progressX = (width / 2) * value;
      if(value > 0.5){
        firstOverValue = (firstOverValue === 0) ? value : firstOverValue;
        //마지막의 경우 좀 더 진행률 이해를 위해 적게 표시 > value - 50
        let tobeX = (width / 2 - 20 ) * firstOverValue - 50
        if(tobeX > progressX){
          // = (width / 2) * firstOverValue;
          progressX = tobeX;
        }
        //console.log("progressX : " + progressX);
      }
      if(value > 0.9 && value <= 1){
        progressX = prevX
      }
      prevX = progressX;
      progressBar.fillRect((width / 4), (height / 2 + 50), progressX, 100);
      //percentText.setText(parseInt(value * 100) + '%');
    });

    /*this.load.on('fileprogress', (file) => {
      console.log('Loading asset: ' + file.key)
    });*/

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      //percentText.destroy();
    })
  }

}
