import JudgementToImage from '../converter/JudgementToImage';

export default class Judgement {
  constructor(scene) {
    this.scene = scene;
    this.judgementToImage = new JudgementToImage(scene);
    this.judgementImage = null;
  }

  updateJudgementImage() {
    if (this.scene.judgementText) {
      if (this.judgementImage) {
        this.judgementImage.destroy();
      }
      this.judgementImage = this.judgementToImage.convertImage(this.scene.judgementText);
      this.scene.judgementText = null;
    }
  }

  changeJudgementText(message) {
    this.scene.judgementText = message;
    this.updateJudgementImage();
  }
}