export default class Coordinate {
    constructor() {
        this.xPosit = {
            width: window.game.config.width,
            center: window.game.config.width / 2,
            centerLeft: window.game.config.width / 2 - 100,

            // Position of node and node route
            keyD: 0 + 90,
            keyF: window.game.config.width / 4 + 90,
            keyJ: window.game.config.width * 2 / 4 + 90,
            keyK: window.game.config.width * 3 / 4 + 90,
            

            centerRouteKeyD: 60,
            centerRouteKeySPACE: 165, 
            centerRouteKeyK: 435,
        };

        this.yPosit = {
            mobileStartBottom: 1838,
            webStartBottom: 1838,
            
            height: window.game.config.height,
            center: window.game.config.height / 2,
            centerBottom: window.game.config.height * 3 / 4,
            
            nodeRoute: window.game.config.height / 8,

            /* judgement Line*/ 
            judgementLine: window.game.config.height * 7 / 10 + 120,
        }

        this.width = {
            /* node size */
            keyD: 190,
            keyF: 190,
            keyJ: 190,
            keyK: 190,
            

            /* node route size*/
            nodeRoute: window.game.config.width / 4,

            /* judgement Line*/
            judgementLine: 1128,


            /* main scene game select object */
            gameSelectBox1: 896,
            gameSelectBox2: 914,
            gameSelectBox3: 921,
            gameSelectBox4: 982,
            gameSelectBox5: 939,

        }

        this.height = {
            node: 84,
            nodeRoute: window.game.config.height * 3 / 5 + 200,
            judgementLine: 4,
            modal: 300,
            gameSelectBox1: 633,
            gameSelectBox2: 744,
            gameSelectBox3: 702,
            gameSelectBox4: 713,
            gameSelectBox5: 842,
        }

        this.color = {
            nodeRoute: 0xffffff,
            pressednodeRoute: 0x00CCFF,
            node: 0x00ffaa,
            judgementLine: 0xFF0066,
            modal: 0x000000,
        }
    }
}
