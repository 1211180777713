import Coordinate from '../theme/Coordinate';

export default class Countdown {
  constructor (scene) {
    this.scene = scene;
    this.coordinate = new Coordinate ();
    this.isPause = false;
    const device = this.scene.isMobile ? 'mob' : 'web';
    this.countdownImages = {
      3: `game_${device}_countdown_3`,
      2: `game_${device}_countdown_2`,
      1: `game_${device}_countdown_1`,
    };
    this.padCover = null;
  }

  setCountdown (seconds, device) {
    const correction = device === 'mob' ? 0.5 : 1;
    this.countdownImage = this.scene.add
      .image (
        this.coordinate.xPosit.center,
        this.coordinate.yPosit.center,
        this.countdownImages[seconds]
      )
      .setOrigin (0.5, 0.5)
      .setDepth (9);
    var x_gap = device === 'mob' ? 7 : 0;
    var y_gap = device === 'mob' ? -15 : 0;
    this.padCover = this.scene.add
      .image (
        this.scene.pad_frame.x - 210 * correction + x_gap,
        this.scene.pad_frame.y + 20 * correction + y_gap,
        `game_${device}_countdown_pad_cover`
      )
      .setOrigin (0)
      .setDepth (9);

    this.startCountdown (seconds);
  }

  startCountdown (seconds) {
    const timerEvent = this.scene.time.addEvent ({
      delay: 1000,
      callback: () => {
        this.isGameStarted = false;
        if (!this.scene.isPause) {
          this.scene.countdownNumber--;
          if (this.scene.countdownNumber >= 0) {
            this.countdownImage.setTexture (
              this.countdownImages[this.scene.countdownNumber]
            );
          }
          if (this.scene.countdownNumber === 0) {
            timerEvent.remove ();
            this.startGame ();
            this.countdownImage.setVisible (false);
            if (this.padCover) {
              this.padCover.setVisible (false);
            }
          }
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  /* dev. down sound */
  startGame () {
    this.scene.bgm.play ({volume: 1}); //0~1
    this.scene.isGameStarted = true;
  }

  updateIsPauseTrue () {
    this.isPause = true;
  }

  updateIsPauseFalse () {
    this.isPause = false;
  }
}
