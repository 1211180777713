const gameKeys = ["D", "F", "J", "K"];

export default class MobileTouchEvent {
  constructor(scene) {
    this.scene = scene;
  }

  loadTouchScreen() {
    gameKeys.forEach((gameKey) => {
      const lowerKey = gameKey.toLowerCase();
      this.scene[`key_${lowerKey}_touch_area`].on("pointerdown", () => {
        this.scene.keyboardEvent.handleKeyDown(gameKey, this.scene.device);
      });
      this.scene[`key_${lowerKey}_touch_area`].on("pointerup", () => {
        this.scene.keyboardEvent.handleKeyUp(gameKey, this.scene.device);
      });
    });
  }

  toggleTouchPad() {
    if (this.scene.on_popup) {
      gameKeys.forEach((gameKey) => {
        const lowerKey = gameKey.toLowerCase();
        this.scene[`key_${lowerKey}_touch_area`].off("pointerdown");
        this.scene[`key_${lowerKey}_touch_area`].off("pointerup");
      });
    } else {
      gameKeys.forEach((gameKey) => {
        const lowerKey = gameKey.toLowerCase();
        this.scene[`key_${lowerKey}_touch_area`].on("pointerdown", () => {
          this.scene.keyboardEvent.handleKeyDown(gameKey, this.scene.device);
        });
        this.scene[`key_${lowerKey}_touch_area`].on("pointerup", () => {
          this.scene.keyboardEvent.handleKeyUp(gameKey, this.scene.device);
        });
      });
    }
  }
}