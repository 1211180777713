/* 게임별 획득점수에 따른 랭킹 분류 후 랭킹 이미지 별명 리턴 */ 
export default class ScoreToRank{
    constructor(gameIndex, gameSocre){
        this.gameIndex = gameIndex;
        this.gameSocre = gameSocre;
    }

    getRank(){
        let rank = 'C'; 
        switch (this.gameIndex) {
            case 1:
                /* Input the score to divide the ranking |S, A, B|*/
                /* MAX SCORE : 231320 */
                rank = this.determineRank(208188, 185056, 161924);
                break;
            case 2:
                /* MAX SCORE : 182480 */
                rank = this.determineRank(164232, 135776, 118804);
                break;
            case 3:
                /* MAX SCORE : 87440 */
                rank = this.determineRank(78696, 69952, 61208);
                break;
            case 4:
                /* MAX SCORE : 169720 */
                rank = this.determineRank(152748, 135776, 118804);
                break;
            case 5:
                /* MAX SCORE : 92720 */
                rank = this.determineRank(83448, 74176, 64904);
                break;
            default:
                break;
        }
        return rank;
    }

    determineRank(sRank, aRank, bRank){
        if(this.gameSocre >= sRank){
            return "s"
        }else if(this.gameSocre >= aRank){
            return "a"
        }else if(this.gameSocre >= bRank){
            return "b"
        }else{
            return "c"
        }
    }
}