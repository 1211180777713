import Coordinate from "../theme/Coordinate";
import Node from "./Node";

export default class NodeManager {
    constructor(scene, nodeFile, isPause, isMobile = false) {
        this.scene = scene;
        this.nodeFile = nodeFile;
        this.isPause = isPause;
        this.isMobile = isMobile;
        this.classOfNodes = [];
        this.nodes = [];
        this.timers = [];
        this.coordinate = new Coordinate();
        
    }

    /* Make node property of node class from text file */
    makeClassFromText() {
        let lines = this.nodeFile.split('\n');
        lines.forEach(line => {
            /* Parsing data of node file each line */
            const [startTime, key] = line.replace(/[{}]/g, '').split(',').map(item => item.trim());
            this.classOfNodes.push(new Node(parseInt(startTime), key.toUpperCase()));
        });
        return this.classOfNodes;
    }

    /* Create a node according to the set time */
    makeRectFromClass() {
        this.classOfNodes = this.makeClassFromText();

        const gap = this.scene.isMobile? 200 : 0;

        this.classOfNodes.forEach(node => {
            let timer = this.scene.time.addEvent({
                /* Delay : count down + node start time */
                delay: this.scene.countdownNumber * 1000 + node.startTime + gap,
                callback: () => {
                    if (!this.isPause) {
                        this.createNode(node);
                    }
                },
                loop: false,
                callbackScope: this
            });
            this.timers.push(timer);
        });
        return this.nodes;
    }

    

    /* Make node object from node class */
    createNode(node) {
        const size = this.isMobile? 0.5 : 1;
        const correction_x = this.isMobile? -175 : -30;

        var nodeRect = this.scene.add.image(
            this.coordinate.xPosit['key'+node.key] ,
            this.coordinate.yPosit.nodeRoute, 
            'game_web_node_'+node.key 
        )
        nodeRect.x += nodeRect.width / 2 + correction_x;
        nodeRect.setOrigin(0.5);
        nodeRect.setScale(size)
        nodeRect.setDepth(3);
        nodeRect.setData('startTime', node.startTime);
        nodeRect.setData('key', node.key);
        if (node.key.indexOf('_') > -1) {
            nodeRect.setData('multiPress', true)
        }
        this.nodes.push(nodeRect);
    }

    /* All timer stop when modal loads */
    updateIsPauseTrue() {
        this.isPause = true;
        this.pauseAllTimers();
    }
    
    /* All timer restart when moadl removed */
    updateIsPauseFalse() {
        this.isPause = false;
        this.resumeAllTimers();
    }
    
    pauseAllTimers() {
        this.timers.forEach(timer => {
            timer.paused = true;
        });
    }

    resumeAllTimers() {
        this.timers.forEach(timer => {
            if (!timer.hasDispatched) {
                timer.paused = false;
            }
        });
    }
    /* Increse node y value until node */
    nodeSlider() {
        const drop_distance = this.isMobile? 200 : 550
        this.scene.nodes.forEach(node => {
            if (node.y < this.coordinate.height.nodeRoute + drop_distance) {
                node.y += this.scene.speed;
            }else{
                var nodeYPosit = node.y;
                this.scene.keyboardEvent.missJudgement(nodeYPosit);
                this.removeNode(node);
            }
        });
    }

    /* unused */
    // clearNodes(){
    //     if(this.scene.nodes){
    //         this.scene.nodes.forEach(node => {
    //             this.removeNode(node)
    //         });
    //         this.scene.nodes = [];
    //     }
    // }

    removeNode(node){
        const index = this.scene.nodes.indexOf(node);
        if (index !== -1) {
            this.scene.nodes.splice(index, 1);
            node.destroy();       
        }
        node = null;
    }
}

